import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Bar = styled(Box)(() => ({
    width: '100%',
    height: '50px',
    backgroundColor: 'white',
    border: '1px solid #f0f0f0',
    bottom: 0,
    display: 'flex',
    flex: 1,
}));

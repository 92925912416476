import { useEffect, useState } from 'react';

import {
    Avatar,
    ChatContainer,
    ConversationHeader,
    MainContainer,
    Message,
    MessageInput,
    MessageList,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import MDEditor from '@uiw/react-md-editor';
import PropTypes from 'prop-types';

import {
    Button,
    Card,
    Image,
    message,
    Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { Box, Grid, Typography } from '@mui/material';

import aiAssistantAvatar from '../../../../assets/aiAssistantAvatar.png';
import aiAssistantImg from '../../../../assets/aiAssistantImg.png';
import studentAvatar from '../../../../assets/studentAvatar.png';
import { useGetChatbotModelQuery } from '../../../../store/services/restApi';

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

export const AiAssistant = (props) => {
    const { onSend, chatRecord, loading, error, showInitial } = props;

    const [messageApi, contextHolder] = message.useMessage();

    const [placeholder, setPlaceholder] = useState('');
    const [newQuestion, setNewQuestion] = useState('');

    const { data: chatbotModelInfo = {} } = useGetChatbotModelQuery();

    const messageInputDisable = loading || error;

    useEffect(() => {
        const messageInputElement = document.querySelector('div.cs-message-input__content-editor');
        if (messageInputElement && messageInputElement.getAttribute('contenteditable') === 'true') {
            messageInputElement.setAttribute('contenteditable', 'plaintext-only');
        }
    }, [messageInputDisable]);

    useEffect(() => {
        const messageContentElements = document.querySelectorAll('section.cs-message--incoming div.cs-message__content');
        if (messageContentElements.length > 0) {
            messageContentElements.forEach((element) => {
                element.style.whiteSpace = 'normal';
            });
        }
    }, [chatRecord, newQuestion]);

    useEffect(() => {
        if (error) {
            messageApi.error('AI 助教出了點問題，請稍後重新整理後再試一次！');
            setPlaceholder('AI 助教出了點問題，請稍後重新整理後再試一次！');
        }
        else {
            setPlaceholder('輸入問題...');
        }
    }, [error]);

    const handleAskNewQuestion = (question) => {
        setNewQuestion(question.replaceAll('<br>', '\n'));
        onSend(question);
    };

    const showChatRecord = () => (chatRecord === undefined || chatRecord === null
        ? ('')
        : chatRecord
            .map((messageItem) => (
                <Message
                    key={messageItem.timestamp}
                    model={{
                        direction: messageItem.role === 'user' ? 'outgoing' : 'incoming',
                        type: 'custom',
                    }}
                >
                    <Avatar
                        src={
                            messageItem.role === 'user'
                                ? studentAvatar
                                : aiAssistantAvatar
                        }
                        name={
                            messageItem.role === 'user'
                                ? 'student'
                                : 'aiAssistant'
                        }
                    />
                    <Message.CustomContent>
                        {
                            messageItem.role === 'user'
                                ? (
                                    <Typography>
                                        {messageItem.content.replaceAll('<br>', '\n')}
                                    </Typography>
                                )
                                : (
                                    <Box data-color-mode="light">
                                        <MDEditor.Markdown
                                            source={messageItem.content}
                                        />
                                    </Box>
                                )
                        }
                    </Message.CustomContent>
                </Message>
            )));

    return (
        <>
            {contextHolder}
            <Card
                style={{
                    padding: '0',
                }}
                cover={(
                    <Box>
                        <Grid container direction="row" spacing={2}>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    alt="aiAssistantImage"
                                    src={aiAssistantImg}
                                    style={{
                                        maxWidth: '130px',
                                    }}
                                    preview={false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="h4" align="center" fontWeight="bold">
                                    AI 助教
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            >
                {
                    (chatRecord === undefined || chatRecord === null) && showInitial
                        ? (
                            <Button
                                onClick={() => onSend()}
                                loading={loading}
                                disabled={loading || error}
                                block
                            >
                                問 AI 助教問題
                            </Button>
                        )
                        : (
                            <MainContainer
                                style={{
                                    borderRadius: '5px',
                                }}
                            >
                                <ChatContainer>
                                    <ConversationHeader>
                                        <ConversationHeader.Content userName="AI 助教" />
                                        <Avatar src={aiAssistantAvatar} name="aiAssistantOne" />
                                        <ConversationHeader.Actions>
                                            <Tooltip title={chatbotModelInfo?.message} trigger="click">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </ConversationHeader.Actions>
                                    </ConversationHeader>
                                    <MessageList
                                        typingIndicator={
                                            loading
                                                ? <TypingIndicator content="AI 助教瘋狂輸入中" />
                                                : ('')
                                        }
                                    >
                                        {
                                            showChatRecord()
                                        }
                                        {
                                            loading
                                                ? (
                                                    <Message
                                                        model={{
                                                            direction: 'outgoing',
                                                            type: 'custom',
                                                        }}
                                                    >
                                                        <Avatar src={studentAvatar} name="student" />
                                                        <Message.CustomContent>
                                                            <Typography>
                                                                {newQuestion}
                                                            </Typography>
                                                        </Message.CustomContent>
                                                    </Message>
                                                )
                                                : ('')
                                        }
                                    </MessageList>
                                    <MessageInput
                                        disabled={messageInputDisable}
                                        placeholder={placeholder}
                                        attachButton={false}
                                        onSend={handleAskNewQuestion}
                                    />
                                </ChatContainer>
                            </MainContainer>
                        )
                }
            </Card>
        </>
    );
};

AiAssistant.propTypes = {
    onSend: PropTypes.func.isRequired,
    chatRecord: PropTypes.arrayOf(PropTypes.shape({
        role: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
    })),
    loading: PropTypes.bool,
    error: PropTypes.bool,
    showInitial: PropTypes.bool,
};

AiAssistant.defaultProps = {
    chatRecord: null,
    loading: false,
    error: false,
    showInitial: true,
};

import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Button,
    Col,
    DatePicker,
    Divider,
    Input,
    Row,
    Space,
    Spin,
    Table,
} from 'antd';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { AlertWindow } from '../../../../components/window/AlertWindow';
import {
    useDeleteAssignmentMutation,
    useGetAssignmentByIdQuery,
    useGetCourseDetailQuery,
    useGetProblemStatsByAssignmentQuery,
    useUpdateAssignmentMutation,
} from '../../../../store/services/restApi';
import {
    MEMBER_ROLE,
    MEMBER_STATUS,
} from '../../../course/constants';
import { StatusDetailWindow } from '../../components/window';
import { checkHasSubmit } from '../../utils';

import { GradeWindowContainer } from './GradeWindowContainer';
import { UpdateProblemContainer } from './UpdateProblemContainer';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Title = styled(Typography)({
    marginTop: 5,
    marginBottom: 5,
    fontSize: '14px',
    fontWeight: 'bold',
});

const ActionButton = styled(Button)({
    marginLeft: 5,
    marginRight: 5,
});

const columns = [
    {
        title: '',
        dataIndex: 'number',
        key: 'number',
        align: 'center',
        width: '10%',
    },
    {
        title: '題目名稱',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: '30%',
    },
    {
        // TODO: 新增'待改'
        title: '已繳 / 應繳',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '15%',
        render: (_, data) => (
            // TODO: 重構，把這個 component 拉出來，避免重複渲染造成效能問題
            <StatusDetailWindow
                title={(
                    <Space>
                        <Typography style={{ color: 'blue' }}>{data.hasSubmitCount}</Typography>
                        <Typography style={{ margin: '0px 3px' }}>/</Typography>
                        <Typography style={{ color: 'black' }}>{data.mustSubmitCount}</Typography>
                    </Space>
                )}
                modalTitle="繳交狀況"
                detailData={data.detailData}
                problemId={data.id}
            />
        ),
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: '25%',
        render: (_, record) => (
            <Box display="flex" justifyContent="center" alignItems="center">
                <UpdateProblemContainer problemId={record.id} />
                <ActionButton
                    icon={<DeleteForeverIcon />}
                    onClick={record.onDelete}
                />
            </Box>
        ),
    },
];

export const AssignmentContainer = () => {
    const navigate = useNavigate();
    const { assignmentId, courseId } = useParams();

    const {
        data: assignment = {},
        isSuccess: assignmentIsFetched,
        isLoading: assignmentIsFetching,
    } = useGetAssignmentByIdQuery({ assignmentId });

    const [
        updateAssignment,
    ] = useUpdateAssignmentMutation();

    const [
        deleteAssignment,
        {
            isSuccess: assignmentIsDeleted,
        },
    ] = useDeleteAssignmentMutation();

    const {
        data: assignmentProblems = [],
        isLoading: assignmentProblemsIsFetching,
    } = useGetProblemStatsByAssignmentQuery({ assignmentId });

    const {
        data: course = {},
        isSuccess: courseIsFetched,
    } = useGetCourseDetailQuery({ courseId });

    const [toUpdateAssignmentData, setToUpdateAssignmentData] = useState({});

    const [courseStudents, setCourseStudents] = useState([{}]);
    const [editable, setEditable] = useState(false);
    const [deleteDoubleCheck, setDeleteDoubleCheck] = useState(false);

    const handleSetCourseStudents = () => {
        const students = course.members
            .filter(
                (member) => (
                    member.role === MEMBER_ROLE.STUDENT
                    && member.status === MEMBER_STATUS.JOINED
                ),
            )
            .map((member) => ({
                id: member.member.id,
                name: member.name,
            }));
        setCourseStudents(students);
    };

    const processDetailData = (problem) => {
        const memberDetail = courseStudents.map((student) => {
            const hasSubmit = checkHasSubmit(problem.memberRecords || [], student);

            if (hasSubmit) {
                const memberRecord = problem.memberRecords.find(
                    (record) => record
                                    && record.user
                                    && record.user.username === student.name,
                );
                const judgeResult = (
                    memberRecord.status === null
                        ? null
                        : memberRecord.status
                );
                const scoreResult = (
                    memberRecord.score === null
                        ? null
                        : memberRecord.score
                );

                return {
                    key: student.name,
                    studentId: student.id,
                    name: student.name,
                    type: problem.type,
                    hasSubmit,
                    judgeResult,
                    scoreResult,
                };
            }

            return {
                key: student.name,
                studentId: student.id,
                name: student.name,
                type: problem.type,
                hasSubmit: false,
                judgeResult: null,
                scoreResult: null,
            };
        });

        return memberDetail;
    };

    const handleRemoveProblem = async (problemId) => {
        const problemIds = (assignment.problems || []).map((id) => parseInt(id, 10));
        problemIds.splice(problemIds.indexOf(problemId), 1);
        await updateAssignment({
            assignmentId,
            problems: problemIds,
        });
    };

    const handleEdit = async () => {
        if (editable) {
            await updateAssignment({
                assignmentId,
                ...toUpdateAssignmentData,
            });
        }
        setEditable(!editable);
    };

    const handleCancelEdit = () => {
        setEditable(false);
        setToUpdateAssignmentData({
            ...assignment,
        });
    };

    const handleAssignmentChange = (data) => {
        setToUpdateAssignmentData({
            ...toUpdateAssignmentData,
            ...data,
        });
    };

    useEffect(() => {
        if (courseIsFetched) {
            handleSetCourseStudents();
        }
    }, [courseIsFetched]);

    useEffect(() => {
        if (assignmentIsFetched) {
            setToUpdateAssignmentData({
                ...assignment,
            });
        }
    }, [assignmentIsFetched, assignment, assignmentId]);

    useEffect(() => {
        if (assignmentIsDeleted) {
            navigate(`/teacher/courses/${courseId}`);
        }
    }, [assignmentIsDeleted]);

    const assignmentInfoBlock = (
        <Row>
            <Col span={7} />
            <Col span={10}>
                <Title>主題名稱：</Title>
                <Input
                    value={toUpdateAssignmentData.title}
                    size="medieum"
                    style={{ marginBottom: 7 }}
                    disabled={!editable}
                    onChange={(e) => handleAssignmentChange({ title: e.target.value })}
                />

                <Title>繳交期限：</Title>
                <RangePicker
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: '100%', marginBottom: 7 }}
                    size="medieum"
                    disabled={!editable}
                    value={[
                        dayjs(`${toUpdateAssignmentData.startDate}`, `YYYY-MM-DD${'T'}HH:mm:ss`),
                        dayjs(`${toUpdateAssignmentData.endDate}`, `YYYY-MM-DD${'T'}HH:mm:ss`),
                    ]}
                    onChange={(value) => {
                        handleAssignmentChange({
                            startDate: value[0].format(`YYYY-MM-DD${'T'}HH:mm:ss`),
                            endDate: value[1].format(`YYYY-MM-DD${'T'}HH:mm:ss`),
                        });
                    }}
                />

                <Title>備註：</Title>

                <TextArea
                    value={toUpdateAssignmentData.description}
                    autoSize={{ minRows: 3, maxRows: 7 }}
                    size="medieum"
                    style={{ marginBottom: 7 }}
                    disabled={!editable}
                    onChange={(e) => handleAssignmentChange({ description: e.target.value })}
                />

            </Col>
            <Col span={7}>
                <Box
                    marginLeft="24px"
                >
                    <Button
                        onClick={handleEdit}
                        type={editable ? 'primary' : 'default'}
                    >
                        { editable ? '儲存' : '編輯' }
                    </Button>
                    <Button
                        onClick={handleCancelEdit}
                        style={{
                            visibility: editable ? 'visible' : 'hidden',
                            marginLeft: '24px',
                        }}
                    >
                        取消
                    </Button>
                </Box>
                <Box
                    marginTop="24px"
                    marginLeft="24px"
                >
                    <Button
                        danger
                        onClick={() => setDeleteDoubleCheck(true)}
                    >
                        刪除
                    </Button>
                </Box>
            </Col>
        </Row>
    );

    const problemsBlock = (
        <Row>
            <Col span={2} />
            <Col span={20}>
                <Table
                    columns={columns}
                    dataSource={assignmentProblems.map((problem, index) => ({
                        ...problem,
                        name: problem.title,
                        key: problem.id,
                        number: index + 1,
                        detailData: processDetailData(problem),
                        onDelete: () => handleRemoveProblem(problem.id),
                    }))}
                    pagination={false}
                    loading={assignmentProblemsIsFetching}
                />
            </Col>
            <Col span={2} />
        </Row>
    );

    return (
        <Spin spinning={assignmentIsFetching}>
            {assignmentInfoBlock}
            <Divider style={{ margin: '16px 0', borderTopWidth: '3px' }} />
            {problemsBlock}
            <AlertWindow
                visible={deleteDoubleCheck}
                message="確定刪除?"
                onOk={() => {
                    setDeleteDoubleCheck(false);
                    deleteAssignment({ assignmentId });
                }}
                onCancel={() => {
                    setDeleteDoubleCheck(false);
                }}
            />
            <GradeWindowContainer />
        </Spin>
    );
};

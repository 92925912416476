import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import {
    useGetCandidateProblemsQuery,
    useGetProblemByIdQuery,
    useUpdateProblemMutation,
} from '../../../../store/services/restApi';
import { Creation } from '../../../problem/components/creation';
import { CREATE_OPTION_VALUE } from '../../../problem/constants';

export const UpdateProblemContainer = ({ problemId }) => {
    const [updateProblem] = useUpdateProblemMutation();
    const { data: candidateProblems = [] } = useGetCandidateProblemsQuery();
    const { data: defaultProblem = {} } = useGetProblemByIdQuery({ problemId });
    const allProblems = candidateProblems.find((p) => p.id === problemId)
        ? candidateProblems
        : [...candidateProblems, defaultProblem];

    const problemData = {
        ...defaultProblem,
        selectedOption: CREATE_OPTION_VALUE.CHOOSE,
        selectedProblem: problemId,
    };

    const tags = []; // TODO: fetch from backend

    const handleSubmit = (problem) => {
        updateProblem({ problemId, ...problem });
    };

    return (
        <Creation
            icon={<ModeEditOutlineIcon />}
            defaultData={problemData}
            allProblems={allProblems}
            allTags={tags}
            onSubmit={handleSubmit}
            disabledColumns={['selectedOption', 'isPrivate']}
        />
    );
};

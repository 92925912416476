import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { REQUEST_STATUS } from '../../../../constants';
import { AiAssistant } from '../../components/ai-assistant';
import {
    getProblemFeedbackHistory,
    requestProblemFeedback,
    selectProblemFeedbackHistory,
    selectProblemFeedbackHistoryStatus,
    selectRequestProblemFeedbackProblem,
    selectRequestProblemFeedbackResult,
    selectRequestProblemFeedbackStatus,
} from '../../problemSlice';

export const ProblemAiAssistant = ({ problemId }) => {
    const dispatch = useDispatch();
    // Feedback
    const requestFeedbackStatus = useSelector(selectRequestProblemFeedbackStatus);
    const requestFeedbackProblem = useSelector(selectRequestProblemFeedbackProblem);
    const requestFeedbackResult = useSelector(selectRequestProblemFeedbackResult);
    // History
    const problemFeedbackHistoryStatus = useSelector(selectProblemFeedbackHistoryStatus);
    const problemFeedbackHistory = useSelector(selectProblemFeedbackHistory);

    // state
    const loading = requestFeedbackProblem === problemId && (
        requestFeedbackStatus === REQUEST_STATUS.LOADING
        || problemFeedbackHistoryStatus === REQUEST_STATUS.LOADING
    );
    const error = requestFeedbackProblem === problemId && (
        requestFeedbackStatus === REQUEST_STATUS.ERROR
        || problemFeedbackHistoryStatus === REQUEST_STATUS.ERROR
    );
    const chatRecord = requestFeedbackProblem === problemId && (
        requestFeedbackStatus === REQUEST_STATUS.SUCCESS
    ) ? requestFeedbackResult?.message : problemFeedbackHistory?.message;

    // Fetch record feedback history
    useEffect(() => {
        dispatch(getProblemFeedbackHistory({ problemId }));
    }, [problemId]);

    const handleRequestFeedback = (message = null) => {
        dispatch(requestProblemFeedback({ problemId, message }));
    };

    return (
        <AiAssistant
            onSend={handleRequestFeedback}
            chatRecord={chatRecord}
            loading={loading}
            error={error}
            showInitial={false}
        />
    );
};

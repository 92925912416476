import { useRef } from 'react';

import { useParams } from 'react-router-dom';

import {
    useCreateProblemMutation,
    useGetAssignmentByIdQuery,
    useGetCandidateProblemsQuery,
    useUpdateAssignmentMutation,
} from '../../../../store/services/restApi';
import { Creation } from '../../../problem/components/creation';

export const CreateProblemContainer = () => {
    const creationRef = useRef(null);
    const { assignmentId } = useParams();

    const {
        data: assignment = {},
    } = useGetAssignmentByIdQuery({ assignmentId });
    const {
        data: candidateProblems = [],
    } = useGetCandidateProblemsQuery();

    const [updateAssignment] = useUpdateAssignmentMutation();
    const [createProblem] = useCreateProblemMutation();

    const tags = []; // TODO: fetch from backend

    const handleSubmit = async (problem) => {
        const createdProblem = await createProblem(problem).unwrap();
        const problemIds = [
            ...assignment.problems,
            createdProblem.id,
        ];
        await updateAssignment({
            assignmentId,
            problems: problemIds,
        });
        creationRef.current.clear();
    };

    return (
        <Creation
            buttonStyle={{
                width: '100%',
                height: '100%',
                paddingTop: '8px',
            }}
            allProblems={candidateProblems}
            allTags={tags}
            onSubmit={handleSubmit}
            ref={creationRef}
            disabledColumns={['isPrivate']}
        />
    );
};

const checkArray = (data) => Boolean(data.length);

const checkString = (data) => Boolean(data.length);

const checkObject = (data) => data !== null && Object.keys(data).length !== 0;

export const check = (data) => {
    if (Array.isArray(data)) {
        return checkArray(data);
    }
    if (typeof data === 'string') {
        return checkString(data);
    }
    if (typeof data === 'object') {
        return checkObject(data);
    }
    if (typeof data === 'number') {
        return true;
    }
    return false;
};

export const recursiveCheck = (data) => {
    if (!check(data)) {
        return false;
    }
    if (Array.isArray(data)) {
        return data.every((item) => recursiveCheck(item));
    }
    if (typeof data === 'object') {
        return Object.values(data).every((item) => recursiveCheck(item));
    }
    return true;
};

import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import MonacoEditor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetUserRecordsQuery } from '../../../../store/services/restApi';
import {
    getCodeFromLocalStorage, saveCodeToLocalStorage, selectCode, setCode,
} from '../../problemSlice';

export const CodeEditorContainer = forwardRef(({ id, language }, ref) => {
    const monacoRef = useRef(null);
    const dispatch = useDispatch();
    const code = useSelector(selectCode);
    const [editorValue, setEditorValue] = useState('');

    const {
        data: userRecords = [],
    } = useGetUserRecordsQuery({ problemId: id });

    const latestRecord = userRecords[0] || {};
    const latestCode = latestRecord.code || '';

    useImperativeHandle(ref, () => ({
        setCode: (newCode) => {
            if (monacoRef.current) {
                monacoRef.current.setValue(newCode);
            }
        },
    }));

    const handleChange = (editor) => {
        dispatch(setCode(editor));
        dispatch(saveCodeToLocalStorage({
            id,
            code: editor,
        }));
    };

    useEffect(() => {
        dispatch(getCodeFromLocalStorage({ id }));
    }, [id, dispatch]);

    useEffect(() => {
        if (code != null) {
            setEditorValue(code);
        }
        else {
            setEditorValue(latestCode);
        }
    }, [code, latestCode]);

    return (
        <MonacoEditor
            height="100%"
            defaultLanguage={language}
            defaultValue={editorValue}
            value={editorValue}
            onChange={handleChange}
            onMount={(editor) => {
                monacoRef.current = editor;
            }}
        />
    );
});

import { Avatar, Space } from 'antd';

import Typography from '@mui/material/Typography';

export const Logo = () => (
    <Space
        width="90px"
        height="32px"
        style={{
            background: 'rgba(0, 0, 0, 0.5)',
            padding: '3px 10px',
            margin: '10px 15px 10px 0',
            borderRadius: '4px',
        }}
    >
        <Avatar src={<img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="avatar" />} />
        <Typography variant="h5">SCT</Typography>
    </Space>
);

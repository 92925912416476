import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URLS, IMGUR_CLIENT_ID } from '../../constants/service';

export const imgurApi = createApi({
    reducerPath: 'imgurApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URLS.IMGUR,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Client-ID ${IMGUR_CLIENT_ID}`);
        },
    }),
    endpoints: (builder) => ({
        uploadImage: builder.mutation({
            query: ({ image }) => ({
                url: '/',
                method: 'POST',
                body: {
                    image,
                },
            }),
        }),
    }),
});

export const {
    useUploadImageMutation,
} = imgurApi;

import { Divider } from 'antd';

import {
    Code,
    ErrorMessage,
    TestCase,
} from './block';

export const ErrorReport = ({ data }) => {
    const status = data?.status;
    const input = (data?.input || [''])[0];
    const output = (data?.output || [''])[0];

    return (
        <>
            <ErrorMessage message={output} />
            <Divider />
            <Code code={data?.code} />
            {
                status === null && (
                    <TestCase testCase={input} />
                )
            }
        </>
    );
};

import { useEffect, useState } from 'react';

import {
    Form,
    Radio,
    Select,
    Space,
} from 'antd';

import { CREATE_OPTION_VALUE } from '../../constants';

export const Option = ({ options, data, onChange = () => {}, disabled = false }) => {
    const [selectedOption, setSelectedOption] = useState(data.selectedOption);
    const [selectedProblem, setSelectedProblem] = useState(data.selectedProblem);

    useEffect(() => {
        onChange({
            selectedOption,
            selectedProblem,
        });
    }, [selectedOption, selectedProblem]);

    const handleFormValuesChange = (changedValues, allValues) => {
        setSelectedOption(allValues.selectedOption);
        setSelectedProblem(allValues.selectedProblem);
    };

    return (
        <Form
            name="optionForm"
            onValuesChange={handleFormValuesChange}
            initialValues={data}
        >
            <Form.Item name="selectedOption">
                <Radio.Group
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '10%',
                    }}
                >
                    <Space direction="vertical">
                        <Radio value={CREATE_OPTION_VALUE.SELF} disabled={disabled}>自行出題</Radio>
                        <Radio value={CREATE_OPTION_VALUE.CHOOSE} disabled={disabled}>題庫選題</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name="selectedProblem"
                style={{
                    width: '50%',
                    position: 'absolute',
                    left: '25%',
                }}
                rules={[{
                    required: true,
                    message: '請選擇題目',
                }]}
            >
                <Select
                    options={options}
                    placeholder="請選擇題目"
                    disabled={disabled}
                    style={{
                        display: (
                            selectedOption === CREATE_OPTION_VALUE.CHOOSE
                                ? 'block'
                                : 'none'
                        ),
                    }}
                />
            </Form.Item>
        </Form>
    );
};

import { useState } from 'react';

import {
    Button,
    Col,
    Row,
} from 'antd';
import {
    CaretDownOutlined,
    CaretRightOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Title = styled(Typography)({
    display: 'flex',
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 'bold',
});

const ContentBox = styled(Box)({
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 4,
});

export const BaseTestCaseBlock = ({
    title = '',
    block = {},
    content = '',
    onRemove = () => {},
}) => {
    const [visible, setVisible] = useState(true);

    return (
        <>
            <Row>
                <Col span={2}>
                    <Button
                        type="link"
                        onClick={() => {
                            setVisible(!visible);
                        }}
                        icon={visible ? <CaretDownOutlined /> : <CaretRightOutlined />}
                        style={{ marginBottom: 3 }}
                    />
                </Col>
                <Col
                    span={20}
                    style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Title>
                        {title}
                    </Title>
                </Col>
                <Col
                    span={2}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        type="link"
                        onClick={() => {
                            onRemove(block.key);
                        }}
                        icon={<DeleteOutlined />}
                    />
                </Col>
            </Row>
            {visible && (
                <ContentBox>{content}</ContentBox>
            )}
        </>
    );
};

import { useEffect, useState } from 'react';

import { Modal } from 'antd';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ALERT_TYPE } from '../../constants/alert';

const getAlertProps = (type) => {
    switch (type) {
        case ALERT_TYPE.SUCCESS.type:
            return ALERT_TYPE.SUCCESS;
        case ALERT_TYPE.ERROR.type:
            return ALERT_TYPE.ERROR;
        case ALERT_TYPE.WARNING.type:
            return ALERT_TYPE.WARNING;
        default:
            return ALERT_TYPE.OTHER;
    }
};

export const AlertWindow = ({
    title = null,
    message = '',
    type = '',
    visible = false,
    onOk = () => {},
    onCancel = () => {},
}) => {
    const [isOpen, setIsOpen] = useState(visible);

    const { text: alertTitle, color: alertColor } = getAlertProps(type);

    useEffect(() => {
        setIsOpen(visible);
    }, [visible]);

    const windowTitle = (
        <Typography style={{ fontWeight: 'bold', alertColor }}>
            {title || alertTitle}
        </Typography>
    );

    return (
        <Modal
            title={windowTitle}
            open={isOpen}
            onOk={() => {
                onOk();
            }}
            onCancel={() => {
                onCancel();
            }}
        >
            <Box style={{ alertColor }}>
                {message}
            </Box>
        </Modal>
    );
};

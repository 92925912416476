import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REQUEST_STATUS } from '../../constants/status';

import {
    fetchProblemFeedbackHistory,
    fetchRecordFeedbackHistory,
    sendRequestProblemFeedback,
    sendRequestRecordFeedback,
} from './problemApi';

const initialState = {
    language: 'python',
    code: '',
    userInput: '',

    executeResult: null,

    sendRequestProblemFeedbackStatus: REQUEST_STATUS.NONE,
    sendRequestProblemFeedbackProblem: null,
    sendRequestProblemFeedbackResult: null,

    sendRequestRecordFeedbackStatus: REQUEST_STATUS.NONE,
    sendRequestRecordFeedbackRecord: null,
    sendRequestRecordFeedbackResult: null,

    fetchProblemFeedbackHistoryStatus: REQUEST_STATUS.NONE,
    problemFeedbackHistory: null,
    fetchRecordFeedbackHistoryStatus: REQUEST_STATUS.NONE,
    recordFeedbackHistory: null,

    // local storage
    storagedCodes: JSON.parse(localStorage.getItem('code') || '{}'),
};

export const requestProblemFeedback = createAsyncThunk(
    'record/requestProblemFeedback',
    async ({ problemId, message = '' }) => {
        const response = await sendRequestProblemFeedback({ problemId, message });
        return response;
    },
);

export const requestRecordFeedback = createAsyncThunk(
    'record/requestRecordFeedback',
    async ({ recordId, type, message = '' }) => {
        const response = await sendRequestRecordFeedback({ recordId, type, message });
        return response;
    },
);

export const getProblemFeedbackHistory = createAsyncThunk(
    'record/getProblemFeedbackHistory',
    async ({ problemId }) => {
        const response = await fetchProblemFeedbackHistory({ problemId });
        return response;
    },
);

export const getRecordFeedbackHistory = createAsyncThunk(
    'record/getRecordFeedbackHistory',
    async ({ recordId, type }) => {
        const response = await fetchRecordFeedbackHistory({ recordId, type });
        return response;
    },
);

export const problemSlice = createSlice({
    name: 'problem',
    initialState,
    reducers: {
        // 監聽使用者輸入
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setCode: (state, action) => {
            state.code = action.payload;
        },
        setUserInput: (state, action) => {
            state.userInput = action.payload;
        },

        // local storage
        getCodeFromLocalStorage: (state, action) => {
            const { id } = action.payload;
            state.code = state.storagedCodes[id];
        },
        saveCodeToLocalStorage: (state, action) => {
            const { id, code } = action.payload;
            state.storagedCodes[id] = code;
            localStorage.setItem('code', JSON.stringify(state.storagedCodes));
        },

        // execute
        setExecuteResult: (state, action) => {
            state.executeResult = action.payload;
        },
        clearExecuteResult: (state) => {
            state.executeResult = null;
        },

        clearStates: () => initialState,

        clearRequestRecordFeedbackStates: (state) => {
            state.sendRequestRecordFeedbackStatus = REQUEST_STATUS.NONE;
            state.sendRequestRecordFeedbackRecord = null;
            state.sendRequestRecordFeedbackResult = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestProblemFeedback.fulfilled, (state, action) => {
                state.sendRequestProblemFeedbackStatus = REQUEST_STATUS.SUCCESS;
                state.sendRequestProblemFeedbackResult = action.payload.chatbotFeedback;
                state.problemFeedbackHistory = action.payload.chatbotFeedback;
            })
            .addCase(requestProblemFeedback.pending, (state, action) => {
                state.sendRequestProblemFeedbackStatus = REQUEST_STATUS.LOADING;
                state.sendRequestProblemFeedbackProblem = action.meta.arg.problemId;
            })
            .addCase(requestProblemFeedback.rejected, (state) => {
                state.sendRequestProblemFeedbackStatus = REQUEST_STATUS.ERROR;
                state.sendRequestProblemFeedbackResult = null;
            })
            .addCase(requestRecordFeedback.fulfilled, (state, action) => {
                state.sendRequestRecordFeedbackStatus = REQUEST_STATUS.SUCCESS;
                state.sendRequestRecordFeedbackResult = action.payload.requestFeedback;
                state.recordFeedbackHistory = action.payload.requestFeedback;
            })
            .addCase(requestRecordFeedback.pending, (state, action) => {
                state.sendRequestRecordFeedbackStatus = REQUEST_STATUS.LOADING;
                state.sendRequestRecordFeedbackRecord = action.meta.arg.recordId;
            })
            .addCase(requestRecordFeedback.rejected, (state) => {
                state.sendRequestRecordFeedbackStatus = REQUEST_STATUS.ERROR;
                state.sendRequestRecordFeedbackResult = null;
            })
            .addCase(getProblemFeedbackHistory.fulfilled, (state, action) => {
                state.fetchProblemFeedbackHistoryStatus = REQUEST_STATUS.SUCCESS;
                state.problemFeedbackHistory = action.payload.chatbotHistory[0];
            })
            .addCase(getProblemFeedbackHistory.pending, (state) => {
                state.fetchProblemFeedbackHistoryStatus = REQUEST_STATUS.LOADING;
            })
            .addCase(getProblemFeedbackHistory.rejected, (state) => {
                state.fetchProblemFeedbackHistoryStatus = REQUEST_STATUS.ERROR;
                state.problemFeedbackHistory = null;
            })
            .addCase(getRecordFeedbackHistory.fulfilled, (state, action) => {
                state.fetchRecordFeedbackHistoryStatus = REQUEST_STATUS.SUCCESS;
                state.recordFeedbackHistory = action.payload.assistHistory[0];
            })
            .addCase(getRecordFeedbackHistory.pending, (state) => {
                state.fetchRecordFeedbackHistoryStatus = REQUEST_STATUS.LOADING;
            })
            .addCase(getRecordFeedbackHistory.rejected, (state) => {
                state.fetchRecordFeedbackHistoryStatus = REQUEST_STATUS.ERROR;
                state.recordFeedbackHistory = null;
            });
    },
});

export const {
    setLanguage,
    setCode,
    setUserInput,

    getCodeFromLocalStorage,
    saveCodeToLocalStorage,

    setExecuteResult,
    clearExecuteResult,

    clearStates,
    clearRequestRecordFeedbackStates,
} = problemSlice.actions;

export const selectCode = (state) => state.problem.code;
export const selectLanguage = (state) => state.problem.language;
export const selectUserInput = (state) => state.problem.userInput;

export const selectExecuteResult = (state) => state.problem.executeResult;

export const selectRequestProblemFeedbackStatus = (state) => (
    state.problem.sendRequestProblemFeedbackStatus
);
export const selectRequestProblemFeedbackProblem = (state) => (
    state.problem.sendRequestProblemFeedbackProblem
);
export const selectRequestProblemFeedbackResult = (state) => (
    state.problem.sendRequestProblemFeedbackResult
);

export const selectRequestRecordFeedbackStatus = (state) => (
    state.problem.sendRequestRecordFeedbackStatus
);
export const selectRequestRecordFeedbackRecord = (state) => (
    state.problem.sendRequestRecordFeedbackRecord
);
export const selectRequestRecordFeedbackResult = (state) => (
    state.problem.sendRequestRecordFeedbackResult
);

export const selectProblemFeedbackHistoryStatus = (state) => (
    state.problem.fetchProblemFeedbackHistoryStatus
);
export const selectProblemFeedbackHistory = (state) => (
    state.problem.problemFeedbackHistory
);
export const selectRecordFeedbackHistoryStatus = (state) => (
    state.problem.fetchRecordFeedbackHistoryStatus
);
export const selectRecordFeedbackHistory = (state) => (
    state.problem.recordFeedbackHistory
);

export default problemSlice.reducer;

export const MEMBER_STATUS = {
    JOINED: '已加入',
    PENDING: '待審核',
    REJECTED: '已拒絕',
};

export const MEMBER_ROLE = {
    TEACHER: '教師',
    TA: '助教',
    STUDENT: '學生',
};

import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const Input = ({ name, label, required = false, password = false, onChange }) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleInputChange = (event) => {
        if (typeof onChange === 'undefined') {
            return;
        }
        onChange(event.target.value);
    };

    const passwordAdornment = (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    return (
        <FormControl
            sx={{ marginTop: '5px' }}
            variant="outlined"
            fullWidth
            required={required}
        >
            <InputLabel>
                {label}
            </InputLabel>
            <OutlinedInput
                name={name}
                type={(!password || showPassword) ? 'text' : 'password'}
                label={label}
                endAdornment={password ? passwordAdornment : <> </>}
                onChange={handleInputChange}
                style={{ height: '45px' }}
            />
        </FormControl>
    );
};

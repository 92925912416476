import { Button } from 'antd';

import Box from '@mui/material/Box';

export const FirstFooter = ({ onNext = () => {} }) => (
    <Box
        style={{
            display: 'flex',
            justifyContent: 'flex-end',
        }}
    >
        <Button
            type="primary"
            onClick={onNext}
        >
            下一步
        </Button>
    </Box>
);

import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { REQUEST_STATUS } from '../../../constants';
import ROUTES from '../../../router/routes';
import { selectThirdPartyLoginStatus, thirdPartyLogin } from '../../../store/slices/authSlice';

export const CallbackPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const status = useSelector(selectThirdPartyLoginStatus);

    useEffect(() => {
        dispatch(thirdPartyLogin({ code: searchParams.get('code') }));
    }, []);

    useEffect(() => {
        if (status === REQUEST_STATUS.SUCCESS) {
            const redirectUrl = localStorage.getItem('redirectUrl') || ROUTES.HOME.link;
            localStorage.removeItem('redirectUrl');
            navigate(redirectUrl);
        }
        else if (status === REQUEST_STATUS.ERROR) {
            navigate(ROUTES.LOGIN.link);
        }
    }, [status]);
};

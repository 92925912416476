import Grid from '@mui/material/Grid';

import { BaseTemplate } from '../../../../template/pages/with-hocs';
import { CourseListContainer } from '../../containers';

export const CourseListPage = () => {
    const content = (
        <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8}>
                <CourseListContainer />
            </Grid>
            <Grid item xs={2} />
        </Grid>
    );

    return <BaseTemplate content={content} />;
};

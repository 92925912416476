import { useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const CheckBoxAutoComplete = ({ label, placeholder, options, onChange }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectedOptions = (event, newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions);
    };
    useEffect(() => {
        onChange(selectedOptions);
    }, [selectedOptions]);

    return (
        <Autocomplete
            multiple
            limitTags={2}
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
                <li
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                >
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option}
                </li>
            )}
            renderInput={(props) => (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    label={label}
                    placeholder={placeholder}
                />
            )}
            onChange={handleSelectedOptions}
            tagSizeSmall
        />
    );
};

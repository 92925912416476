import { Col, Row } from 'antd';

import { TeacherAdminTemplate } from '../../../../template/pages/with-hocs';
import { TEACHER_SIDE_MENU_NAME } from '../../constants';
import { CreateAssignmentContainer } from '../../containers/teacher';

export const CreateAssignmentPage = () => {
    const content = (
        <Row>
            <Col span={7} />
            <Col span={10}>
                <CreateAssignmentContainer />
            </Col>
            <Col span={7} />
        </Row>
    );

    return (
        <TeacherAdminTemplate
            selectedKey={TEACHER_SIDE_MENU_NAME.CREATE_ASSIGNMENT}
            openKey={TEACHER_SIDE_MENU_NAME.ASSIGNMENTS_LIST}
            content={content}
        />
    );
};

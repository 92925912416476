import { Cookie } from './cookie';

export const getHeaders = () => {
    const token = Cookie.get('token');
    if (!token) {
        return {};
    }
    return {
        Authorization: `JWT ${token}`,
    };
};

export const prepareHeaders = (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
        headers.set('Authorization', `JWT ${token}`);
    }
    else if (Cookie.get('token')) {
        headers.set('Authorization', `JWT ${Cookie.get('token')}`);
    }
    return headers;
};

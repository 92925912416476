import {
    Col,
    Form,
    Input,
    Radio,
    Row,
    Select,
} from 'antd';

const { TextArea } = Input;

const itemRules = {
    title: [{
        required: true,
        message: '請輸入題目標題',
    }],
    description: [{
        required: true,
        message: '請輸入題目描述',
    }],
    inputDescription: [{
        required: true,
        message: '請輸入Input描述',
    }],
    outputDescription: [{
        required: true,
        message: '請輸入Output描述',
    }],
    type: [{
        required: true,
        message: '請選擇批改模式',
    }],
    level: [{
        required: true,
        message: '請選擇題目難度',
    }],
};

const levelOptions = [
    {
        value: 1,
        label: '超簡單',
    },
    {
        value: 2,
        label: '簡單',
    },
    {
        value: 3,
        label: '中等',
    },
    {
        value: 4,
        label: '困難',
    },
    {
        value: 5,
        label: '超困難',
    },
];

export const Info = ({ data, tags = [], onChange = () => {}, onlyPrivate = false }) => {
    const tagOptions = tags.map((tag) => ({
        value: tag.id,
        label: tag.label,
    }));

    const handleFormValuesChange = (changedValues, allValues) => {
        onChange({
            title: allValues.title,
            descriptions: {
                text: allValues.description,
                input: allValues.inputDescription,
                output: allValues.outputDescription,
            },
            type: allValues.type,
            level: allValues.level,
            tags: allValues.tags,
            isPrivate: allValues.isPrivate,
        });
    };

    return (
        <Form
            name="infoForm"
            style={{ paddingTop: '2%' }}
            layout="vertical"
            onValuesChange={handleFormValuesChange}
            initialValues={data || null}
        >
            <Row>
                <Col span={15}>
                    <Form.Item
                        label="標題"
                        name="title"
                        rules={itemRules.title}
                    >
                        <Input placeholder="請輸入題目標題..." />
                    </Form.Item>

                    <Form.Item
                        label="描述"
                        name="description"
                        rules={itemRules.description}
                    >
                        <TextArea
                            placeholder="請輸入題目描述..."
                            autoSize={{
                                minRows: 5,
                                maxRows: 5,
                            }}
                        />
                    </Form.Item>
                    <Row>
                        <Col span={12} style={{ paddingRight: 2 }}>
                            <Form.Item
                                label="Input描述"
                                name="inputDescription"
                                rules={itemRules.inputDescription}
                            >
                                <TextArea
                                    placeholder="請輸入Input描述..."
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 3,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 2 }}>
                            <Form.Item
                                label="Output描述"
                                name="outputDescription"
                                rules={itemRules.outputDescription}
                            >
                                <TextArea
                                    placeholder="請輸入Output描述..."
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 3,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={9} style={{ paddingLeft: '8%' }}>
                    <Form.Item
                        label="公開或私人"
                        name="isPrivate"
                        rules={itemRules.type}
                        tooltip={'選擇 "公開"，將會出現在題庫頁面中'}
                    >
                        <Radio.Group>
                            <Radio value={false} disabled={onlyPrivate}> 公開 </Radio>
                            <Radio value disabled={onlyPrivate}> 私人 </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="批改模式"
                        name="type"
                        rules={itemRules.type}
                    >
                        <Radio.Group>
                            <Radio value="A"> 自動 </Radio>
                            <Radio value="M"> 手動 </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="題目難度"
                        name="level"
                        rules={itemRules.level}
                    >
                        <Select
                            options={levelOptions}
                            style={{ width: '100%' }}
                            placeholder="請選擇題目難度"
                        />
                    </Form.Item>
                    <Form.Item label="Tags" name="tags">
                        <Select
                            allowClear
                            mode="multiple"
                            options={tagOptions}
                            style={{ width: '100%' }}
                            placeholder="請選擇Tags"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

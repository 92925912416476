import { useNavigate, useParams } from 'react-router-dom';

import {
    Button,
    Card,
    Empty,
    Tabs,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Spinner } from '../../../components/spinner';
import ROUTES from '../../../router/routes';
import { useGetUserRecordsQuery } from '../../../store/services/restApi';
import { ErrorReport } from '../components/report';
import { Hint } from '../components/report/block';
import { SuccessReport } from '../components/report/SuccessReport';
import { RECORD_TYPES } from '../constants';

import { RecordAiAssistantContainer } from './RecordAiAssistantContainer';

const REPORT_PAGE_OPTIONS = {
    AI_ASSISTANT: 'aiAssistant',
    HINT: 'hint',
    OTHERS: 'others',
};

const TABS_ZH_LABELS = {
    [REPORT_PAGE_OPTIONS.AI_ASSISTANT]: 'AI助教',
    [REPORT_PAGE_OPTIONS.HINT]: '提示',
    [REPORT_PAGE_OPTIONS.OTHERS]: '其他資訊',
};

const getTabContent = (data, type) => {
    const id = data?.id;
    const submitType = data?.isSubmit ? RECORD_TYPES.SUBMIT : RECORD_TYPES.TEST;
    const hints = data?.hints || [];
    const success = data?.success;
    switch (type) {
        case REPORT_PAGE_OPTIONS.AI_ASSISTANT:
            return (
                <RecordAiAssistantContainer recordId={id} type={submitType} />
            );
        case REPORT_PAGE_OPTIONS.HINT:
            return (
                hints.length > 0
                    ? <Hint hints={hints} />
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            );
        case REPORT_PAGE_OPTIONS.OTHERS:
            return (
                success
                    ? <SuccessReport data={data} />
                    : <ErrorReport data={data} />
            );
        default:
            return null;
    }
};

export const ReportContainer = ({ id }) => {
    const { problemId } = useParams();
    const navigate = useNavigate();

    const {
        data: userRecords = [],
    } = useGetUserRecordsQuery({ problemId });

    const data = (userRecords || []).find((result) => `${result?.id}` === `${id}`);

    const tabItems = Object.values(REPORT_PAGE_OPTIONS).map((label) => ({
        key: label,
        label: (
            <Typography
                variant="h6"
                textTransform="capitalize"
                fontWeight="bold"
                margin="0 24px"
            >
                {TABS_ZH_LABELS[label]}
            </Typography>
        ),
        children: getTabContent(data, label),
    }));

    if (userRecords.length === 0) {
        return (
            <Box
                textAlign="center"
                marginTop="24px"
            >
                <Spinner size={80} />
            </Box>
        );
    }

    return (
        <>
            <Box
                margin="16px 0"
                padding="0 10px"
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="h6" color="#0000003a">
                    #{data?.id}
                </Typography>
                {/* TODO: Show language */}
                <Button
                    icon={<CloseCircleOutlined />}
                    onClick={() => {
                        navigate(`${ROUTES.PROBLEMS.link}/${problemId}/record`);
                    }}
                />
            </Box>
            <Card>
                <Tabs
                    type="card"
                    items={tabItems}
                />
            </Card>
        </>
    );
};

import { SiderContainer } from '../../../features/teacher/containers/teacher';
import { withAuth, withCourseOwner } from '../../../hocs';
import { SiderNoFooterTemplate } from '../bases';

const TeacherAdminTemplate = ({ selectedKey, openKey, content }) => (
    <SiderNoFooterTemplate
        sider={(
            <SiderContainer
                defaultSelectedKey={selectedKey}
                defaultOpenKey={openKey}
            />
        )}
        content={content}
    />
);

export default withCourseOwner(withAuth(TeacherAdminTemplate));

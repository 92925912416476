import { Select } from 'antd';

const getItem = (item) => {
    const { id, code } = item;
    return {
        label: `#${id}`,
        value: code,
        key: id,
    };
};

export const CodeOption = ({ data = [], onSelect = () => {} }) => (
    <Select
        onChange={onSelect}
        options={data.map(getItem)}
        style={{ width: 80 }}
    />
);

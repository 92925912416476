import { Divider } from 'antd';

import {
    CodeCoverage,
    MutationScore,
    TestCase,
} from './block';

export const SuccessReport = ({ data }) => {
    const status = data?.status;
    const input = (data?.input || [''])[0];

    return (
        <>
            <CodeCoverage
                code={data?.code}
                report={data?.report?.coverage}
            />
            <Divider />
            <MutationScore report={data?.report?.mutation} />
            <Divider />
            {
                status === null && (
                    <TestCase testCase={input} />
                )
            }
        </>
    );
};

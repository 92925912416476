import { TeacherAdminTemplate } from '../../../../template/pages/with-hocs';
import { TEACHER_SIDE_MENU_NAME } from '../../constants';
import { CourseContainer } from '../../containers/teacher';

export const CoursePage = () => (
    <TeacherAdminTemplate
        selectedKey={TEACHER_SIDE_MENU_NAME.COURSE_INFO}
        openKey={TEACHER_SIDE_MENU_NAME.ASSIGNMENTS_LIST}
        content={<CourseContainer />}
    />
);

import {
    Col,
    Row,
    Tabs,
} from 'antd';

import { withAuth } from '../../../../hocs';
import { useGetMyDetailQuery } from '../../../../store/services/restApi';
import { BaseTemplate } from '../../../../template/pages/with-hocs';
import { OwnedProblemListContainer } from '../../containers';

const items = [
    {
        key: 'owned',
        label: '建立的題目',
        children: <OwnedProblemListContainer />,
    },
    {
        key: 'submitted',
        label: '已作答的題目',
        children: <h1>已作答的題目... 開發中</h1>, // TODO: submitted problem list
    },
];

const MyProblemPage = () => {
    const { data: userDetail = {} } = useGetMyDetailQuery();

    const content = (
        <Row>
            <Col span={4} />
            <Col span={16}>
                <Row>
                    <Col span={24}>
                        <Tabs
                            items={
                                userDetail?.isManager
                                    ? items
                                    : items.slice(1)
                            }
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={4} />
        </Row>
    );
    return (
        <BaseTemplate content={content} />
    );
};

export default withAuth(MyProblemPage);

import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Button,
    Modal,
    Table,
} from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

import { TagFactory } from '../../../../components/tag/factories';
import { basePalette } from '../../../../theme/baseTheme';

const isAutoType = (type) => (type || '').toLowerCase() === 'a';

const columns = [
    {
        title: '名字',
        dataIndex: 'name',
        align: 'left',
        render: (_, data) => data.name,
    },
    {
        title: '繳交',
        dataIndex: 'hasSubmit',
        align: 'center',
        render: (_, data) => (
            data.hasSubmit
                ? <CheckCircleTwoTone twoToneColor={basePalette.success_light} />
                : '-'
        ),
    },
    {
        title: '結果',
        dataIndex: 'result',
        align: 'center',
        render: (_, data) => {
            if (isAutoType(data.type)) {
                return data.judgeResult === null
                    ? '-'
                    : TagFactory.get(data.judgeResult);
            }
            return data.scoreResult === null
                ? '-'
                : data.scoreResult;
        },
    },
    {
        title: '',
        dataIndex: 'action',
        align: 'center',
        render: (action, data) => {
            if (isAutoType(data.type)) {
                return null;
            }
            return (
                <Button
                    disabled={!data.hasSubmit}
                    onClick={() => {
                        action.score();
                    }}
                >
                    批改
                </Button>
            );
        },
    },
];

export const StatusDetailWindow = ({
    title,
    modalTitle,
    detailData = [],
    problemId,
    onOk = () => {},
}) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const openWindow = () => {
        setIsOpen(true);
        navigate(`${problemId}`);
    };

    const closeWindow = () => {
        setIsOpen(false);
        navigate(-1);
    };

    const tableData = detailData.map((data) => ({
        ...data,
        action: {
            score: () => navigate(`${data.studentId}`),
        },
    }));

    return (
        <>
            <Button onClick={openWindow}>
                {title}
            </Button>
            <Modal
                title={modalTitle}
                open={isOpen}
                onOk={() => {
                    onOk();
                    closeWindow();
                }}
                onCancel={closeWindow}
                footer={null}
                style={{
                    position: 'absolute',
                    top: '25%',
                    left: '35%',
                }}
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    size="small"
                    style={{ minHeight: '200px' }}
                />
            </Modal>
        </>
    );
};

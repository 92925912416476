import { forwardRef } from 'react';

import { Panel as ResizeablePanel } from 'react-resizable-panels';

import Box from '@mui/material/Box';

export const Panel = forwardRef(({ children, style = {}, ...props }, ref) => (
    <ResizeablePanel
        style={{
            display: 'flex',
            flexDirection: 'column',
            ...style,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
    >
        <Box
            sx={{
                height: '100%',
                width: '100%',
                backgroundColor: 'var(--color-panel-background)',
                display: 'block',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                borderRadius: '0.5rem',
            }}
        >
            {children}
        </Box>
    </ResizeablePanel>
));

import { Col, Row } from 'antd';

import { Spinner } from '../../components/spinner';

export const CenterSpinner = ({ size = 100 }) => (
    <Row style={{ marginTop: '64px' }}>
        <Col span={8} />
        <Col
            span={8}
            style={{
                textAlign: 'center',
            }}
        >
            <Spinner size={size} />
        </Col>
        <Col span={8} />
    </Row>
);

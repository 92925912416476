import { useState } from 'react';

import {
    Card,
    Col,
    Rate,
    Row,
    Space,
    Tag,
} from 'antd';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { TestCaseBlock } from '../../../../components/code-block';

const Title = styled(Typography)({
    fontWeight: '600',
});
Title.defaultProps = {
    variant: 'h4',
};

const Subtitle = styled(Typography)({
    marginTop: '16px',
    fontWeight: '600',
});
Subtitle.defaultProps = {
    variant: 'h6',
};

const Paragraph = styled(Typography)({
    whiteSpace: 'pre-line',
    lineHeight: '2em',
    marginLeft: '2em',
});
Paragraph.defaultProps = {
    paragraph: true,
};

export const Description = ({
    id,
    title,
    descriptions,
    examples,
    level,
    tags,
}) => {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (
        <Card
            bodyStyle={{
                overflowY: isHover ? 'auto' : 'hidden',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {/* Title and Level */}
            <Row>
                <Col span={18}>
                    <Space size="large">
                        <Title>{id}</Title>
                        <Title>{title}</Title>
                    </Space>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Subtitle level={3}>難度</Subtitle>
                    <Rate disabled value={level} style={{ color: '#ffd250', marginLeft: '1.5em' }} />
                </Col>
            </Row>

            {/* Tags */}
            <Row>
                <Col span={24}>
                    {
                        tags.map((tag) => (
                            <Tag color="blue" key={tag.id}>
                                {tag.label}
                            </Tag>
                        ))
                    }
                </Col>
            </Row>

            {/* Description */}
            <Row>
                <Col span={24}>
                    <Subtitle level={3}>題目描述</Subtitle>
                    <Paragraph>
                        {descriptions.text}
                    </Paragraph>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Subtitle level={4}>輸入</Subtitle>
                    <Paragraph>
                        {descriptions.input}
                    </Paragraph>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Subtitle level={4}>輸出</Subtitle>
                    <Paragraph>
                        {descriptions.output}
                    </Paragraph>
                </Col>
            </Row>

            {/* Examples */}
            {
                examples.map((example, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Row key={`example-${index}`}>
                        <Col span={24}>
                            <Subtitle level={3}>範例 {index + 1}</Subtitle>
                            <Row>
                                <Col span={24}>
                                    <Subtitle level={4}>輸入</Subtitle>
                                    <TestCaseBlock
                                        style={{ marginLeft: '2em' }}
                                        text={example.input}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Subtitle level={4}>輸出</Subtitle>
                                    <TestCaseBlock
                                        style={{ marginLeft: '2em' }}
                                        text={example.output}
                                        isInput={false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ))
            }
        </Card>
    );
};

import { SiderContent } from '../../../components/layout';
import { HeaderContainer } from '../../containers';

export const SiderNoFooterTemplate = ({ sider, content }) => (
    <>
        <HeaderContainer />
        <SiderContent
            sider={sider}
            content={content}
        />
    </>
);

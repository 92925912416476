import { basePalette } from '../theme/baseTheme';

export const ALERT_TYPE = {
    SUCCESS: {
        type: 'success',
        text: '成功',
        color: basePalette.success_main,
    },
    ERROR: {
        type: 'error',
        text: '錯誤',
        color: basePalette.error_main,
    },
    WARNING: {
        type: 'warning',
        text: '警告',
        color: basePalette.warning_main,
    },
    OTHER: {
        type: 'other',
        text: '提示',
        color: basePalette.black,
    },
};

export const getInitialProblem = () => ({
    title: '',
    descriptions: {
        text: '',
        input: '',
        output: '',
    },
    examples: [],
    testCases: [],
    level: '',
    type: '',
    tags: [],
    notes: [],
    defaultCode: '',
    answer: '',
    isPrivate: true,
});

export const CREATE_OPTION_VALUE = {
    SELF: 'self',
    CHOOSE: 'choose',
};

export const RECORD_TYPES = {
    SUBMIT: 'SUBMIT_RECORD',
    TEST: 'EXECUTE_RECORD',
    EXECUTE: 'EXECUTE_ERROR',
};

export const RECORD_TYPES_ZH = {
    [RECORD_TYPES.SUBMIT]: '提交',
    [RECORD_TYPES.TEST]: '測試',
};

import BaseTemplate from './BaseTemplate';
import LoginTemplate from './LoginTemplate';
import NoFooterTemplate from './NoFooterTemplate';
import SiderNoFooterTemplate from './SiderNoFooterTemplate';
import TeacherAdminTemplate from './TeacherAdminTemplate';

export {
    BaseTemplate,
    LoginTemplate,
    NoFooterTemplate,
    SiderNoFooterTemplate,
    TeacherAdminTemplate,
};

import { useEffect, useState } from 'react';

import {
    Button,
    Modal,
    Steps,
} from 'antd';

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import { FirstFooter } from './FirstFooter';
import { LastFooter } from './LastFooter';
import { MiddleFooter } from './MiddleFooter';

export const CreateProblemWindow = ({
    icon = <AddIcon />,
    buttonStyle = {},
    stepContent = '',
    submit = () => {},
    isChecked = false,
    submitText = '新增',
}) => {
    const { option, info, testcase, answer, check } = stepContent;

    const [isOpen, setIsOpen] = useState(false);

    const steps = [
        {
            id: 'option',
            title: '出題方式',
            content: option,
        },
        {
            id: 'info',
            title: '基本資訊',
            content: info,
        },
        {
            id: 'testcase',
            title: '題目測資',
            content: testcase,
        },
        {
            id: 'answer',
            title: '參考解答',
            content: answer,
        },
        {
            id: 'check',
            title: '檢查內容',
            content: check,
        },
    ];
    const [current, setCurrent] = useState(0);
    const [content, setContent] = useState(steps[0].content);
    const items = steps.map((item) => ({
        key: item.id,
        title: item.title,
    }));

    const openWindow = () => {
        setIsOpen(true);
        setCurrent(0);
        setContent(steps[0].content);
    };

    const closeWindow = () => {
        setIsOpen(false);
    };

    const onChange = (step) => {
        setCurrent(step);
        setContent(steps[step].content);
    };

    useEffect(() => {
        onChange(current);
    }, [stepContent]);

    const getFooter = () => {
        if (current === 0) {
            return (
                <FirstFooter
                    onNext={() => onChange(current + 1)}
                />
            );
        }
        if (current === steps.length - 1) {
            return (
                <LastFooter
                    onBack={() => onChange(current - 1)}
                    onSubmit={() => {
                        submit();
                        closeWindow();
                    }}
                    isChecked={isChecked}
                    submitText={submitText}
                />
            );
        }

        return (
            <MiddleFooter
                onBack={() => onChange(current - 1)}
                onNext={() => onChange(current + 1)}
            />
        );
    };

    return (
        <>
            <Button
                icon={icon}
                onClick={openWindow}
                style={buttonStyle}
            />
            <Modal
                open={isOpen}
                onCancel={() => {
                    closeWindow();
                }}
                width={900}
                bodyStyle={{ height: '450px', padding: '0px 10px 0px 10px' }}
                footer={getFooter()}
            >
                <Steps
                    current={current}
                    onChange={onChange}
                    items={items}
                    style={{ padding: '20px 25px 0px 20px' }}
                />
                <Box style={{ height: '400px', overflow: 'auto' }}>
                    {content}
                </Box>
            </Modal>
        </>
    );
};

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

export const Header = ({ children }) => (
    <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" sx={{ zIndex: 999 }}>
            <Toolbar>
                {children}
            </Toolbar>
        </AppBar>
    </Box>
);

import { BaseTemplate } from '../../../../template/pages/with-hocs';
import { ProblemListContainer } from '../../containers';

export const ProblemListPage = () => {
    const content = (
        <ProblemListContainer />
    );
    return (
        <BaseTemplate content={content} />
    );
};

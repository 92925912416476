import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './Hint.css';

const Item = styled(Box)(() => ({
    margin: 0,
    height: '160px',
    color: '#000',
    lineHeight: '160px',
    textAlign: 'center',
    fontSize: '36px',
}));

export const Hint = ({ hints }) => (
    <>
        <Typography variant="h6" fontWeight="bold">
            提示
        </Typography>
        <Carousel
            arrows
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
        >
            {hints.map((hint) => (
                <Item
                    key={`${hint}`}
                >
                    {hint}
                </Item>
            ))}
        </Carousel>
    </>
);

import { Content } from '../../../components/layout';
import {
    FooterContainer,
    HeaderContainer,
} from '../../containers';

export const BaseTemplate = ({ content }) => (
    <>
        <HeaderContainer />
        <Content
            sx={{
                minHeight: 'calc(100vh - 64px)',
            }}
        >
            {content}
        </Content>
        <FooterContainer />
    </>
);

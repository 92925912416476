import { createTheme } from '@mui/material/styles';

import {
    basePalette,
    fontFamily,
    mode,
} from './baseTheme';

const muiTheme = createTheme({
    palette: {
        mode,
        primary: {
            main: basePalette.primary_main,
            light: basePalette.primary_light,
            dark: basePalette.primary_dark,
            contrastText: basePalette.white,
        },
        secondary: {
            main: basePalette.secondary_main,
            light: basePalette.secondary_light,
            dark: basePalette.secondary_dark,
            contrastText: basePalette.black,
        },
        error: {
            main: basePalette.error_main,
            light: basePalette.error_light,
            dark: basePalette.error_dark,
            contrastText: basePalette.white,
        },
        warning: {
            main: basePalette.warning_main,
            light: basePalette.warning_light,
            dark: basePalette.warning_dark,
            contrastText: basePalette.white,
        },
        success: {
            main: basePalette.success_main,
            light: basePalette.success_light,
            dark: basePalette.success_dark,
            contrastText: basePalette.white,
        },
        white: {
            main: basePalette.white,
            light: basePalette.white,
            dark: basePalette.white,
            contrastText: basePalette.primary_main,
        },
    },
    typography: {
        fontFamily,
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 16,
            color: basePalette.white,
        },
        h1: {
            fontSize: 120,
        },
    },
});

export default muiTheme;

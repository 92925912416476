const ROUTES = {
    HOME: {
        text: '首頁',
        link: '/',
    },
    COURSES: {
        text: '課程',
        link: '/courses',
    },
    PROBLEMS: {
        text: '題庫',
        link: '/problems',
    },
    MANUAL: {
        text: '使用手冊',
        link: 'https://hackmd.io/@OoMarkoO/sct-manual',
    },
    LOGIN: {
        text: '登入',
        link: '/login',
    },
    REGISTER: {
        text: '註冊',
        link: '/register',
    },
    LOGOUT: {
        text: '登出',
        link: '/logout',
    },
    MY_COURSES: {
        text: '我的課程',
        link: '/my-courses',
    },
    MY_PROBLEMS: {
        text: '我的題目',
        link: '/my-problems',
    },
    TEACHER_COURSES: {
        text: '教師課程',
        link: '/teacher/courses',
    },
    TEACHER_CREATE_ASSIGNMENT: {
        text: '新增主題',
        link: '/teacher/courses/:courseId/new/assignment',
    },
    TEACHER_COURSE: {
        text: '課程後台',
        link: '/teacher/courses/:courseId',
    },
    TEACHER_COURSE_ASSIGNMENT: {
        text: '主題',
        link: '/teacher/courses/:courseId/:assignmentId',
    },
    TEACHER_ASSIGNMENT_PROBLEM: {
        text: '繳交狀況',
        link: '/teacher/courses/:courseId/:assignmentId/:problemId/',
    },
    TEACHER_SCORE: {
        text: '批改',
        link: '/teacher/courses/:courseId/:assignmentId/:problemId/:studentId',
    },
    CALLBACK: {
        text: '第三方回調',
        link: '/callback',
    },
};

export default ROUTES;

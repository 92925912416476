import Box from '@mui/material/Box';

import { NavButton } from '../../button';

export const ManualNavLink = ({ items }) => (
    <Box
        flexGrow={1}
        justifyContent="flex-end"
        display={{
            xs: 'none',
            md: 'flex',
        }}
    >
        {items.map(({ text, link }) => (
            <NavButton
                text={text}
                link={link}
                key={text}
                buttonStyle={{ fontSize: '20px' }}
            />
        ))}
    </Box>
);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REQUEST_STATUS } from '../../constants/status';
import { Cookie } from '../../utils/cookie';
import {
    sendLoginRequest,
    sendThirdPartyLoginRequest,
    sendVerifyTokenRequest,
} from '../services/authApi';

const initialState = {
    loginStatus: REQUEST_STATUS.NONE,
    loginMessage: '',

    thirdPartyLoginStatus: REQUEST_STATUS.NONE,

    verifyTokenStatus: REQUEST_STATUS.NONE,

    isAuthenticated: false,
};

export const login = createAsyncThunk(
    'auth/sendLoginRequest',
    async ({ username, password }) => {
        const response = await sendLoginRequest({ username, password });
        return response.data;
    },
);

export const thirdPartyLogin = createAsyncThunk(
    'auth/sendThirdPartyLoginRequest',
    async ({ code }) => {
        const response = await sendThirdPartyLoginRequest({ code });
        return response.data;
    },
);

export const verify = createAsyncThunk(
    'auth/sendVerifyTokenRequest',
    async ({ token }) => {
        const response = await sendVerifyTokenRequest({ token });
        return response.data;
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearStates: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                Cookie.set('token', action.payload.token);
                state.loginStatus = REQUEST_STATUS.SUCCESS;
                state.verifyTokenStatus = REQUEST_STATUS.SUCCESS;
                state.isAuthenticated = true;
            })
            .addCase(login.rejected, (state) => {
                state.loginStatus = REQUEST_STATUS.ERROR;
                state.verifyTokenStatus = REQUEST_STATUS.ERROR;
                state.loginMessage = '帳號或密碼錯誤';
            })
            .addCase(login.pending, (state) => {
                state.loginStatus = REQUEST_STATUS.LOADING;
                state.verifyTokenStatus = REQUEST_STATUS.NONE;
                state.loginMessage = '';
            });
        builder
            .addCase(verify.fulfilled, (state) => {
                state.verifyTokenStatus = REQUEST_STATUS.SUCCESS;
                state.isAuthenticated = true;
            })
            .addCase(verify.rejected, (state) => {
                state.verifyTokenStatus = REQUEST_STATUS.ERROR;
                state.isAuthenticated = false;
            })
            .addCase(verify.pending, (state) => {
                state.verifyTokenStatus = REQUEST_STATUS.LOADING;
                state.isAuthenticated = false;
            });
        builder
            .addCase(thirdPartyLogin.fulfilled, (state, action) => {
                Cookie.set('token', action.payload.token);
                state.thirdPartyLoginStatus = REQUEST_STATUS.SUCCESS;
                state.verifyTokenStatus = REQUEST_STATUS.SUCCESS;
                state.isAuthenticated = true;
            })
            .addCase(thirdPartyLogin.rejected, (state) => {
                state.thirdPartyLoginStatus = REQUEST_STATUS.ERROR;
                state.verifyTokenStatus = REQUEST_STATUS.ERROR;
            })
            .addCase(thirdPartyLogin.pending, (state) => {
                state.thirdPartyLoginStatus = REQUEST_STATUS.LOADING;
                state.verifyTokenStatus = REQUEST_STATUS.NONE;
            });
    },
});

export const {
    clearStates,
} = authSlice.actions;

export const selectLoginStatus = (state) => state.auth.loginStatus;
export const selectLoginMessage = (state) => state.auth.loginMessage;

export const selectVerifyTokenStatus = (state) => state.auth.verifyTokenStatus;

export const selectThirdPartyLoginStatus = (state) => state.auth.thirdPartyLoginStatus;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

export default authSlice.reducer;

import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    Space,
} from 'antd';

import { Button as MuiButton } from '@mui/material';

import { TagFactory } from '../../../../components/tag/factories';
import { AlertWindow } from '../../../../components/window/AlertWindow';
import ROUTES from '../../../../router/routes';
import {
    useExecuteMutation,
    useGetProblemByIdQuery,
    useGetUserSubmissionRecordQuery,
    useSubmitMutation,
} from '../../../../store/services/restApi';
import { selectIsAuthenticated, verify } from '../../../../store/slices/authSlice';
import { Cookie } from '../../../../utils/cookie';
import { Bar, BarItem } from '../../components/console';
import {
    clearRequestRecordFeedbackStates,
    selectCode,
    selectLanguage,
    selectUserInput,
    setExecuteResult,
} from '../../problemSlice';

export const BarContainer = ({ id, handleOpen }) => {
    const { data: problem = {} } = useGetProblemByIdQuery({ problemId: id });
    const { data: userSubmissions = [] } = useGetUserSubmissionRecordQuery({ problemId: id });
    const [
        submit,
        {
            data: submitResult,
            isSuccess: submitted,
            isLoading: submitting,
        },
    ] = useSubmitMutation();
    const [
        execute,
        {
            data: executeResult,
            isSuccess: executed,
            isLoading: executing,
        },
    ] = useExecuteMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAuthenticated = useSelector(selectIsAuthenticated);

    const language = useSelector(selectLanguage);
    const code = useSelector(selectCode);
    const userInput = useSelector(selectUserInput);

    const isManual = problem?.type === 'm';
    const hasSubmit = userSubmissions.length > 0;

    const [alertVisible, setAlertVisible] = useState(false);

    const loading = submitting || executing;

    useEffect(() => {
        if (!isAuthenticated) {
            dispatch(verify({ token: Cookie.get('token') }));
        }
    }, []);

    useEffect(() => {
        if (executed) {
            dispatch(setExecuteResult(executeResult));
            dispatch(clearRequestRecordFeedbackStates());
        }
    }, [executed]);

    const handleSubmit = () => {
        submit({ problemId: id, code, language });
    };

    const handleTest = () => {
        execute({ code, problemId: id, testCase: userInput, language });
    };

    return (
        <>
            <Bar>
                <BarItem>
                    <Button onClick={handleOpen}>
                        控制台
                    </Button>
                </BarItem>
                <BarItem textAlign="right" justifyContent="center">
                    <Space>
                        {
                            submitted && (
                                <MuiButton
                                    style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        padding: 0,
                                    }}
                                    onClick={() => {
                                        navigate(`${ROUTES.PROBLEMS.link}/${id}/record/report/${submitResult?.id}`);
                                    }}
                                >
                                    {submitResult?.status === 'PA'
                                        ? TagFactory.get(
                                            submitResult?.status,
                                            submitResult.marked?.filter(
                                                (mark) => mark === 1,
                                            ).length || undefined,
                                            submitResult.marked?.length || undefined,
                                        )
                                        : TagFactory.get(submitResult?.status)}
                                </MuiButton>
                            )
                        }
                        {
                            (isManual && hasSubmit) && TagFactory.get('HAS_SUBMIT')
                        }
                        <Button
                            onClick={handleTest}
                            disabled={loading || !isAuthenticated}
                            loading={executing}
                        >
                            測試
                        </Button>
                        <Button
                            onClick={() => setAlertVisible(true)}
                            disabled={(isManual && hasSubmit) || loading || !isAuthenticated}
                            loading={submitting}
                            type="primary"
                        >
                            提交
                        </Button>
                    </Space>
                </BarItem>
            </Bar>
            { alertVisible && (
                <AlertWindow
                    visible={alertVisible}
                    message={`確定提交? ${isManual ? '提交後就不能更改了!' : ''}`}
                    onOk={() => {
                        setAlertVisible(false);
                        handleSubmit();
                    }}
                    onCancel={() => {
                        setAlertVisible(false);
                    }}
                />
            )}
        </>
    );
};

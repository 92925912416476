import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { REQUEST_STATUS } from '../../constants';
import ROUTES from '../../router/routes';
import {
    useGetCourseDetailQuery,
    useGetMyDetailQuery,
} from '../../store/services/restApi';
import { selectVerifyTokenStatus } from '../../store/slices/authSlice';
import { BaseLoadingPage, TeacherAdminLoadingPage } from '../../template/loading';

// eslint-disable-next-line react/function-component-definition
export const withCourseRendering = (Component, shouldRender) => (props) => {
    const { courseId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data: course = {},
        isError: courseFetchError,
        refetch: refetchCourseDetail,
    } = useGetCourseDetailQuery({ courseId });

    const { data: userDetail = {} } = useGetMyDetailQuery();

    const verifyStatus = useSelector(selectVerifyTokenStatus);
    const [needRender, setNeedRender] = useState(false);

    const isTeacherAdminPage = location.pathname.includes('teacher');

    const courseData = {
        ...course,
        myDetail: userDetail,
    };

    const redirect = () => {
        if (isTeacherAdminPage) {
            navigate(ROUTES.MY_COURSES.link, { replace: true });
        }
        else {
            navigate(ROUTES.COURSES.link, { replace: true });
        }
    };

    // fetch course error
    useEffect(() => {
        if (courseFetchError) {
            redirect();
        }
    }, [courseFetchError]);

    useEffect(() => {
        // 已經驗證過，且未通過就直接 redirect
        if (verifyStatus === REQUEST_STATUS.ERROR) {
            redirect();
        }

        // 避免 reload 沒有 state
        if (courseData?.id !== courseId) {
            refetchCourseDetail({ courseId });
        }
    }, []);

    useEffect(() => {
        if (courseData?.id === courseId) {
            if (shouldRender(courseData)) {
                setNeedRender(true);
            }
            else {
                redirect();
            }
        }
    }, [JSON.stringify(courseData)]);

    if (needRender) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...props} />;
    }
    if (isTeacherAdminPage) {
        return <TeacherAdminLoadingPage />;
    }
    return <BaseLoadingPage />;
};

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Code = ({ code }) => (
    <Box>
        <Typography variant="h6" fontWeight="bold">
            程式碼：
        </Typography>
        <SyntaxHighlighter
            language="python"
            wrapLines
            style={vs}
            showLineNumbers
        >
            {code}
        </SyntaxHighlighter>
    </Box>
);

export class ApiFormatter {
    static text(string) {
        return JSON
            .stringify(string.replace(/\r\n/g, '\n'))
            .slice(1, -1);
    }

    static json(string) {
        return JSON
            .stringify(JSON.stringify(string))
            .slice(1, -1);
    }

    static base64(string) {
        return string.replace(/^data:image\/(png|jpg);base64,/, '');
    }
}

import { useEffect, useRef, useState } from 'react';

import MDEditor, { commands } from '@uiw/react-md-editor';
import { useParams } from 'react-router-dom';

import CodeIcon from '@mui/icons-material/Code';

import { defaultMdLeftCommands } from '../../../constants/commands';
import { useUploadImageMutation } from '../../../store/services/imgurApi';
import { useCreateArticleMutation, useGetUserRecordsQuery } from '../../../store/services/restApi';
import { ApiFormatter } from '../../../utils/ApiFormatter';
import { makeHash } from '../../../utils/random';
import { CodeCapture } from '../components/capturer';
import { CreateArticleWindow } from '../components/window';

export const CreateArticleWindowContainer = () => {
    const { problemId } = useParams();
    const editorRef = useRef(null);
    const windowRef = useRef(null);

    const { data: userRecords = [] } = useGetUserRecordsQuery({ problemId });
    const [
        createArticle,
        {
            isSuccess: articleCreated,
        },
    ] = useCreateArticleMutation();

    const [
        uploadImage,
        {
            data: uploadedImage,
        },
    ] = useUploadImageMutation();

    const [article, setArticle] = useState('');
    const [mdTextApi, setMdTextApi] = useState(null);
    const [imageHash, setImageHash] = useState(null);

    const codeOptions = userRecords.map((record) => ({
        id: record.id,
        code: record.code,
        timestamp: record.timestamp,
        key: record.id,
    }));

    const titleRegex = /^# (.+)$/m;
    const matchTitle = article.match(titleRegex);
    const title = matchTitle
        ? matchTitle[1]
        : article.split('\n').filter((x) => x !== '')[0];

    const imageLink = uploadedImage?.data?.link;

    const handleCapture = (image) => {
        uploadImage({ image: ApiFormatter.base64(image) });
        let hash = makeHash(8);
        const text = editorRef?.current?.markdown || '';
        while (text.includes(hash)) {
            hash = makeHash(8);
        }
        setImageHash(hash);
        mdTextApi.replaceSelection(`![Image Uploading...](${hash})\n`);
    };

    const handleCreate = () => {
        createArticle({
            problemId,
            title,
            content: article,
        });
    };

    const createCodeCapture = (handle) => (
        <CodeCapture
            codeOptions={codeOptions}
            onCapture={(image) => {
                handleCapture(image);
                handle.close();
            }}
        />
    );

    useEffect(() => {
        if (imageLink && mdTextApi) {
            const text = editorRef?.current?.markdown || '';
            const start = text.indexOf(`![Image Uploading...](${imageHash})`);
            mdTextApi.setSelectionRange({ start, end: start + 31 });
            mdTextApi.replaceSelection(`![Image](${imageLink})`);
        }
    }, [imageLink]);

    useEffect(() => {
        if (articleCreated) {
            setArticle('');
            windowRef.current.close();
        }
    }, [articleCreated]);

    return (
        <CreateArticleWindow
            onCreate={handleCreate}
            ref={windowRef}
        >
            <MDEditor
                value={article}
                onChange={setArticle}
                height="100%"
                style={{
                    padding: '8px',
                }}
                commands={[
                    ...defaultMdLeftCommands,
                    commands.divider,
                    commands.group([], {
                        name: 'add-code',
                        keyCommand: 'add-code',
                        groupName: 'add-code',
                        icon: <CodeIcon height="12" width="12" fontSize="12" />,
                        buttonProps: { 'aria-label': 'Add code' },
                        children: createCodeCapture,
                        execute: (state, api) => {
                            setMdTextApi(api);
                        },
                    }),
                ]}
                ref={editorRef}
            />
        </CreateArticleWindow>
    );
};

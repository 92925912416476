import { useEffect, useState } from 'react';

import { CodeBlock, googlecode } from 'react-code-blocks';

import { Modal } from 'antd';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ALERT_TYPE } from '../../constants/alert';

export const DefaultCodeAlertWindow = ({
    title = null,
    message = '',
    language = '',
    visible = false,
    okText = '',
    cancelText = '',
    onOk = () => {},
    onCancel = () => {},
}) => {
    const [isOpen, setIsOpen] = useState(visible);

    useEffect(() => {
        setIsOpen(visible);
    }, [visible]);

    const { text: alertTitle, color: alertColor } = ALERT_TYPE.OTHER;

    const windowTitle = (
        <Typography style={{ fontWeight: 'bold' }}>
            {title || alertTitle}
        </Typography>
    );

    return (
        <Modal
            title={windowTitle}
            open={isOpen}
            okText={okText}
            cancelText={cancelText}
            onOk={() => {
                onOk();
            }}
            onCancel={() => {
                onCancel();
            }}
        >
            <Box style={{ alertColor }} sx={{ border: '1px solid #aaa', borderRadius: '5px' }}>
                <CodeBlock
                    text={message}
                    language={language}
                    showLineNumbers
                    theme={googlecode}
                    wrapLines
                    codeBlock
                />
            </Box>
        </Modal>
    );
};

import { PanelGroup } from 'react-resizable-panels';

import Box from '@mui/material/Box';

import { Panel, ResizeHandle } from '../../../../components/resizeable';
import { withAuth } from '../../../../hocs';
import { NoFooterTemplate } from '../../../../template/pages/with-hocs';

import { LeftPageContainer } from './LeftPage';
import { RightPageContainer } from './RightPage';

import './ProblemPage.css';

const ProblemPage = () => {
    const content = (
        <PanelGroup
            direction="horizontal"
            style={{
                height: 'calc(100vh - 80px)',
            }}
        >
            <Panel
                collapsible
                minSize={30}
            >
                <LeftPageContainer />
            </Panel>
            <ResizeHandle
                style={{
                    backgroundColor: '#e0e0e0',
                }}
                direction="horizontal"
            />
            <Panel
                collapsible
                minSize={25}
            >
                <Box>
                    <RightPageContainer />
                </Box>
            </Panel>
        </PanelGroup>
    );
    return (
        <NoFooterTemplate content={content} />
    );
};

export default withAuth(ProblemPage);

import { Link } from 'react-router-dom';

import {
    Checkbox,
    Col,
    Row,
    Table,
} from 'antd';

import Box from '@mui/material/Box';

import ROUTES from '../../../router/routes';
import {
    useGetMyCoursesQuery,
    useGetMyDetailQuery,
} from '../../../store/services/restApi';
import { MEMBER_ROLE } from '../constants';

import { CreateCourseWindowContainer } from './CreateCourseWindowContainer';

const columns = [
    {
        title: '課程名稱',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '40%',
        render: (name, record) => (
            <Box
                component={Link}
                to={`${ROUTES.TEACHER_COURSES.link}/${record.id}`}
            >
                {name}
            </Box>
        ),
    },
    {
        title: '私人',
        dataIndex: 'isPrivate',
        key: 'isPrivate',
        align: 'center',
        width: '40%',
        render: (isPrivate) => (
            isPrivate
                ? <Checkbox defaultChecked disabled />
                : <Checkbox disabled />
        ),
    },
    {
        title: '人數',
        dataIndex: 'members',
        key: 'members',
        align: 'center',
        width: '20%',
        render: (members) => members.length,
    },
];

export const OwnedCourseListContainer = () => {
    const { data: myCourses = [] } = useGetMyCoursesQuery();
    const { data: userDetail = {} } = useGetMyDetailQuery();

    const userId = userDetail?.id;

    const data = myCourses
        .map((course) => ({
            ...course,
            key: course.id,
        }))
        .filter((course) => {
            const role = course.members
                .filter((member) => member.member.id === userId)[0]?.role;
            const isOwner = `${course.owner.id}` === `${userId}`;
            const isTa = role === MEMBER_ROLE.TA;
            return isOwner || isTa;
        });

    return (
        <>
            <Row>
                <Col>
                    <CreateCourseWindowContainer />
                </Col>
                <Col />
            </Row>
            <Table
                columns={columns}
                dataSource={data}
            />
        </>
    );
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URLS } from '../../constants/service';
import { prepareHeaders } from '../../utils/api';

export const restApi = createApi({
    reducerPath: 'restApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URLS.REST,
        prepareHeaders,
    }),
    tagTypes: [
        'Article',
        'Assignment',
        'Course',
        'Problem',
        'Record',
        'User',
    ],
    endpoints: (builder) => ({
        // article
        getAllArticlesByProblem: builder.query({
            query: ({ problemId }) => `article/?problem=${problemId}`,
            providesTags: ['Article'],
        }),
        getArticleById: builder.query({
            query: ({ articleId }) => `article/${articleId}/`,
            providesTags: ['Article'],
        }),
        createArticle: builder.mutation({
            query: ({ problemId, title, content }) => ({
                url: 'article/',
                method: 'POST',
                body: {
                    problem: problemId,
                    title,
                    content,
                },
            }),
            invalidatesTags: ['Article'],
        }),

        // assignment
        getAllAssignmentsByCourse: builder.query({
            query: ({ courseId }) => `assignment/?course=${courseId}`,
            providesTags: ['Assignment'],
        }),
        createAssignment: builder.mutation({
            query: ({
                courseId,
                title,
                description,
                startDate,
                endDate,
            }) => ({
                url: 'assignment/',
                method: 'POST',
                body: {
                    course: courseId,
                    title,
                    description,
                    startDate,
                    endDate,
                },
            }),
            invalidatesTags: ['Assignment'],
        }),
        getAssignmentById: builder.query({
            query: ({ assignmentId }) => `assignment/${assignmentId}/`,
            providesTags: ['Assignment'],
        }),
        updateAssignment: builder.mutation({
            query: ({ assignmentId, ...data }) => ({
                url: `assignment/${assignmentId}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Assignment'],
        }),
        deleteAssignment: builder.mutation({
            query: ({ assignmentId }) => ({
                url: `assignment/${assignmentId}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Assignment', 'Course'],
        }),
        // course
        getAllCourses: builder.query({
            query: () => 'course/',
            providesTags: ['Course'],
        }),
        createCourse: builder.mutation({
            query: ({ courseName, isPrivate }) => ({
                url: 'course/',
                method: 'POST',
                body: {
                    name: courseName,
                    isPrivate,
                },
            }),
            invalidatesTags: ['Course'],
        }),
        getMyCourses: builder.query({
            query: () => 'course/my/',
            providesTags: ['Course'],
        }),
        getCourseDetail: builder.query({
            query: ({ courseId }) => `course/${courseId}/`,
            providesTags: ['Course'],
        }),
        updateCourse: builder.mutation({
            query: ({ courseId, name, isPrivate, memberStatus }) => ({
                url: `course/${courseId}/`,
                method: 'PATCH',
                body: {
                    name,
                    isPrivate,
                    memberStatus,
                },
            }),
            invalidatesTags: ['Course'],
        }),
        joinCourse: builder.mutation({
            query: ({ courseId }) => ({
                url: `course/${courseId}/join/`,
                method: 'POST',
            }),
            invalidatesTags: ['Course'],
        }),

        // problem
        getProblemById: builder.query({
            query: ({ problemId }) => `problem/${problemId}/`,
            providesTags: ['Problem'],
        }),
        getAllProblems: builder.query({
            query: () => 'problem/',
            providesTags: ['Problem'],
        }),
        getProblemStatsByAssignment: builder.query({
            query: ({ assignmentId }) => `problem/stats/?assignment=${assignmentId}`,
            providesTags: ['Assignment'],
        }),
        getMyProblems: builder.query({
            query: () => 'problem/my/',
            providesTags: ['Problem'],
        }),
        getCandidateProblems: builder.query({
            query: () => 'problem/candidate/',
            providesTags: ['Problem'],
        }),
        createProblem: builder.mutation({
            query: ({
                title,
                descriptions,
                examples,
                testCases,
                level,
                tags,
                answer,
                type,
                defaultCode,
                isPrivate,
            }) => ({
                url: 'problem/',
                method: 'POST',
                body: {
                    title,
                    descriptions,
                    examples,
                    testCases,
                    level,
                    tags,
                    answer,
                    type,
                    defaultCode,
                    isPrivate,
                },
            }),
            invalidatesTags: ['Problem'],
        }),
        updateProblem: builder.mutation({
            query: ({ problemId, ...data }) => ({
                url: `problem/${problemId}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Problem', 'Assignment'],
        }),
        deleteProblem: builder.mutation({
            query: ({ problemId }) => ({
                url: `problem/${problemId}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Problem', 'Assignment'],
        }),
        submit: builder.mutation({
            query: ({ problemId, code, language }) => ({
                url: `problem/${problemId}/submit/`,
                method: 'POST',
                body: { code, language },
            }),
            invalidatesTags: ['Problem', 'Assignment', 'Record'],
        }),
        execute: builder.mutation({
            query: ({ problemId, code, testCase, language }) => ({
                url: `problem/${problemId}/execute/`,
                method: 'POST',
                body: { code, testCase, language },
            }),
            invalidatesTags: ['Problem', 'Assignment', 'Record'],
        }),
        getUserRecords: builder.query({
            query: ({ problemId }) => `problem/${problemId}/record/`,
            providesTags: ['Record'],
        }),
        getUserSubmissionRecord: builder.query({
            query: ({ problemId }) => `problem/${problemId}/record/?type=submit`,
            providesTags: ['Record'],
        }),
        getUserExecutionRecord: builder.query({
            query: ({ problemId }) => `problem/${problemId}/record/?type=execute`,
            providesTags: ['Record'],
        }),
        getStudentSubmission: builder.query({
            query: ({ studentId, problemId }) => `problem/${problemId}/submission/?student=${studentId}`,
            providesTags: ['Assignment'],
        }),
        score: builder.mutation({
            query: ({ studentId, problemId, score, comment }) => ({
                url: `problem/${problemId}/score/`,
                method: 'POST',
                body: { student: studentId, score, comment },
            }),
            invalidatesTags: ['Assignment'],
        }),

        // chatbot
        getChatbotModel: builder.query({
            query: () => 'chatbot/model/',
        }),

        // user
        register: builder.mutation({
            query: ({ email, username, password }) => ({
                url: 'user/register/',
                method: 'POST',
                body: {
                    email,
                    username,
                    password,
                },
            }),
        }),
        getMyDetail: builder.query({
            query: () => 'user/me/',
            providesTags: ['User'],
        }),
        getAuthorizeUrl: builder.query({
            query: () => 'user/authorize_url/',
        }),
    }),
});

export const {
    // problem
    useGetProblemByIdQuery,
    useGetAllProblemsQuery,
    useGetProblemStatsByAssignmentQuery,
    useGetMyProblemsQuery,
    useGetCandidateProblemsQuery,
    useCreateProblemMutation,
    useUpdateProblemMutation,
    useDeleteProblemMutation,
    useSubmitMutation,
    useExecuteMutation,
    useGetUserRecordsQuery,
    useGetUserSubmissionRecordQuery,
    useGetUserExecutionRecordQuery,
    useGetStudentSubmissionQuery,
    useScoreMutation,

    // course
    useGetAllCoursesQuery,
    useCreateCourseMutation,
    useGetMyCoursesQuery,
    useGetCourseDetailQuery,
    useUpdateCourseMutation,
    useJoinCourseMutation,

    // article
    useGetAllArticlesByProblemQuery,
    useGetArticleByIdQuery,
    useCreateArticleMutation,

    // assignment
    useGetAllAssignmentsByCourseQuery,
    useCreateAssignmentMutation,
    useGetAssignmentByIdQuery,
    useUpdateAssignmentMutation,
    useDeleteAssignmentMutation,

    // chatbot
    useGetChatbotModelQuery,

    // user
    useRegisterMutation,
    useGetMyDetailQuery,
    useGetAuthorizeUrlQuery,
} = restApi;

import { MEMBER_STATUS } from '../features/course/constants';

import { withCourseRendering } from './bases';

export const withMemberInCourse = (Component) => (
    withCourseRendering(
        Component,
        (course) => {
            const members = course?.members || [];
            return members.some((member) => member.status === MEMBER_STATUS.JOINED);
        },
    )
);

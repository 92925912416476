import { useEffect, useState } from 'react';

import {
    Button,
    Col,
    Form,
    Row,
} from 'antd';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ExampleTestCaseBlock, HiddenTestCaseBlock } from './test-case';

const Title = styled(Typography)({
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    top: 0,
});

const AddButton = styled(Button)({
    width: '25%',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
});

const deepUpdateState = (pre, index, col, value) => {
    const newTestCases = JSON.parse(JSON.stringify(pre));
    newTestCases[index][col] = value;
    return newTestCases;
};

export const TestCase = ({ data = {}, onChange = () => {} }) => {
    const [examples, setExamples] = useState(data.examples || []);
    const [testCases, setTestCases] = useState(data.testCases || []);

    const initialExampleBlocks = Array.from(
        { length: data.examples.length },
        (_, index) => ({ key: index }),
    );

    const initialCaseBlocks = Array.from(
        { length: data.testCases.length },
        (_, index) => ({ key: index }),
    );

    const [exampleBlocks, setExampleBlocks] = useState(initialExampleBlocks);
    const [caseBlocks, setCaseBlocks] = useState(initialCaseBlocks);

    const [nextExampleKey, setNextExampleKey] = useState(data.examples.length);
    const [nextCaseKey, setNextCaseKey] = useState(data.testCases.length);

    const exampleInitialValues = data.examples.reduce((acc, item, index) => {
        acc[`input${index}`] = item.input;
        acc[`output${index}`] = item.output;
        return acc;
    }, {});

    const caseInitialValues = data.testCases.reduce((acc, item, index) => {
        acc[`hint${index}`] = item.hint;
        acc[`input${index}`] = item.input;
        acc[`output${index}`] = item.output;
        return acc;
    }, {});

    useEffect(() => {
        onChange({
            examples,
            testCases,
        });
    }, [JSON.stringify(examples), JSON.stringify(testCases)]);

    // examples
    const addExampleBlock = () => {
        setExampleBlocks((prev) => [...prev, { key: nextExampleKey }]);
        setNextExampleKey((prev) => prev + 1);
        setExamples((prev) => [...prev, { input: '', output: '' }]);
    };

    const removeExampleBlock = (key, index) => {
        setExampleBlocks((prev) => prev.filter((block) => block.key !== key));
        setExamples((prev) => prev.filter((_, i) => i !== index));
    };

    const onExampleInputChange = (value, index) => {
        setExamples((prev) => deepUpdateState(prev, index, 'input', value));
    };

    const onExampleOutputChange = (value, index) => {
        setExamples((prev) => deepUpdateState(prev, index, 'output', value));
    };

    // test cases
    const addCaseBlock = () => {
        setCaseBlocks((prev) => [...prev, { key: nextCaseKey }]);
        setNextCaseKey((prev) => prev + 1);
        setTestCases((prev) => [...prev, { hint: '', input: '', output: '' }]);
    };

    const removeCaseBlock = (key, index) => {
        setCaseBlocks((prev) => prev.filter((block) => block.key !== key));
        setTestCases((prev) => prev.filter((_, i) => i !== index));
    };

    const onTestCaseHintChange = (value, index) => {
        setTestCases((prev) => deepUpdateState(prev, index, 'hint', value));
    };
    const onTestCaseInputChange = (value, index) => {
        setTestCases((prev) => deepUpdateState(prev, index, 'input', value));
    };

    const onTestCaseOutputChange = (value, index) => {
        setTestCases((prev) => deepUpdateState(prev, index, 'output', value));
    };

    return (
        <Row style={{ marginTop: 10 }}>
            <Col span={12} style={{ paddingRight: 8 }}>
                <Title>範例測資</Title>
                <Form
                    layout="vertical"
                    initialValues={exampleInitialValues}
                >
                    {exampleBlocks.map((block, index) => (
                        <ExampleTestCaseBlock
                            index={index}
                            title={`Example ${index + 1}`}
                            key={block.key}
                            block={block}
                            onRemove={removeExampleBlock}
                            onInputChange={(value) => {
                                onExampleInputChange(value.target.value, index);
                            }}
                            onOutputChange={(value) => {
                                onExampleOutputChange(value.target.value, index);
                            }}
                        />
                    ))}
                    <AddButton onClick={addExampleBlock}>
                        + 新增
                    </AddButton>
                </Form>
            </Col>
            <Col span={12} style={{ paddingLeft: 8 }}>
                <Title>隱藏測資</Title>
                <Form
                    layout="vertical"
                    initialValues={caseInitialValues}
                >
                    {caseBlocks.map((block, index) => (
                        <HiddenTestCaseBlock
                            index={index}
                            title={`Test Case ${index + 1}`}
                            key={block.key}
                            block={block}
                            onRemove={removeCaseBlock}
                            onHintChange={(value) => {
                                onTestCaseHintChange(value.target.value, index);
                            }}
                            onInputChange={(value) => {
                                onTestCaseInputChange(value.target.value, index);
                            }}
                            onOutputChange={(value) => {
                                onTestCaseOutputChange(value.target.value, index);
                            }}
                        />
                    ))}
                    <AddButton onClick={addCaseBlock}>
                        + 新增
                    </AddButton>
                </Form>
            </Col>
        </Row>
    );
};

import {
    Col,
    Row,
    Tabs,
} from 'antd';

import { withAuth } from '../../../../hocs';
import { useGetMyDetailQuery } from '../../../../store/services/restApi';
import { BaseTemplate } from '../../../../template/pages/with-hocs';
import {
    JoinedCourseListContainer,
    OwnedCourseListContainer,
} from '../../containers';

const items = [
    {
        key: 'owned',
        label: '建立的課程',
        children: <OwnedCourseListContainer />,
    },
    {
        key: 'joined',
        label: '加入的課程',
        children: <JoinedCourseListContainer />,
    },
];

const MyCoursesPage = () => {
    const { data: userDetail = {} } = useGetMyDetailQuery();

    const content = (
        <Row>
            <Col span={4} />
            <Col span={16}>
                <Row>
                    <Col span={24}>
                        <Tabs
                            items={
                                userDetail?.isManager
                                    ? items
                                    : items.slice(1)
                            }
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={4} />
        </Row>
    );
    return (
        <BaseTemplate content={content} />
    );
};

export default withAuth(MyCoursesPage);

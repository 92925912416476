import { Layout } from 'antd';

const { Sider, Content } = Layout;

export const SiderContent = ({ sider, content }) => (
    <Content
        style={{
            width: '100%',
            marginTop: '64px',
        }}
    >
        <Layout>
            <Sider
                style={{
                    overflow: 'auto',
                    minHeight: '100vh',
                }}
                width="240"
            >
                {sider}
            </Sider>
            <Content
                style={{
                    padding: '24px',
                }}
            >
                {content}
            </Content>
        </Layout>
    </Content>
);

import { gql, request } from 'graphql-request';

import { API_URLS } from '../../constants/service';
import { Cookie } from '../../utils/cookie';

export const sendRequestProblemFeedback = ({ problemId, message }) => {
    const headers = {
        Authorization: `JWT ${Cookie.get('token')}`,
    };
    const operation = gql`
        mutation ChatbotFeedback($problemId: Int!, $message: String) {
            chatbotFeedback(problemId: $problemId, message: $message) {
            problem {
                id
            }
            message {
                role
                content
                timestamp
            }
            success
            }
        }
    `;
    return request(API_URLS.BASE, operation, { problemId, message }, headers);
};

export const sendRequestRecordFeedback = ({ recordId, type, message = '' }) => {
    const headers = {
        Authorization: `JWT ${Cookie.get('token')}`,
    };
    const operation = gql`
        mutation Mutation($recordId: Int!, $type: HistoryType!, $message: String) {
            requestFeedback(recordId: $recordId, type: $type, message: $message){
                record {
                    id
                }
                message {
                    role
                    content
                    timestamp
                }
                type
                success
            }
        }`;
    return request(API_URLS.BASE, operation, { recordId, type, message }, headers);
};

export const fetchProblemFeedbackHistory = ({ problemId }) => {
    const headers = {
        Authorization: `JWT ${Cookie.get('token')}`,
    };
    const operation = gql`
        query ChatbotHistory($problemId: Int!) {
            chatbotHistory(problemId: $problemId) {
            problem {
                id
            }
            message {
                role
                content
                timestamp
            }
            }
        }
    `;
    return request(API_URLS.BASE, operation, { problemId }, headers);
};

export const fetchRecordFeedbackHistory = ({ recordId, type }) => {
    const headers = {
        Authorization: `JWT ${Cookie.get('token')}`,
    };
    const operation = gql`
        query AssistHistory($recordId: Int!, $type: HistoryType!) {
            assistHistory(recordId: $recordId, type: $type) {
                record {
                    id
                }
                message {
                    role
                    content
                    timestamp
                }
            }
        }
        `;
    return request(API_URLS.BASE, operation, { recordId, type }, headers);
};

import { Link } from 'react-router-dom';

import { Table } from 'antd';

import Box from '@mui/material/Box';

import ROUTES from '../../../router/routes';
import {
    useGetMyCoursesQuery,
    useGetMyDetailQuery,
} from '../../../store/services/restApi';

const columns = [
    {
        title: '課程名稱',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '40%',
        render: (name, record) => (
            <Box
                component={Link}
                to={`${ROUTES.COURSES.link}/${record.id}`}
            >
                {name}
            </Box>
        ),
    },
    {
        title: '教師',
        dataIndex: 'owner',
        key: 'owner',
        align: 'center',
        width: '40%',
        render: (owner) => owner.username,
    },
    {
        title: '人數',
        dataIndex: 'members',
        key: 'members',
        align: 'center',
        width: '20%',
        render: (members) => members.length,
    },
];

export const JoinedCourseListContainer = () => {
    const { data: myCourses = [] } = useGetMyCoursesQuery();
    const { data: userDetail = {} } = useGetMyDetailQuery();

    const userId = userDetail?.id;

    const data = myCourses
        .map((course) => ({
            ...course,
            key: course.id,
        }))
        .filter((course) => `${course.owner.id}` !== `${userId}`);

    return (
        <Table
            columns={columns}
            dataSource={data}
        />
    );
};

import { MEMBER_ROLE } from '../features/course/constants';

import { withCourseRendering } from './bases';

export const withCourseOwner = (Component) => withCourseRendering(
    Component,
    (course) => {
        const role = course?.members
            ?.filter((member) => (
                member.name === course?.myDetail?.username
            ))[0]?.role;
        const isTa = role === MEMBER_ROLE.TA;
        const isOwner = course?.owner?.username === course?.myDetail?.username;
        return isOwner || isTa;
    },
);

import { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import MonacoEditor from '@monaco-editor/react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Button,
    Col,
    Form,
    Input,
    Row,
} from 'antd';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
    useExecuteMutation,
    useGetStudentSubmissionQuery,
    useScoreMutation,
} from '../../../../store/services/restApi';
import { OutputBox } from '../../components/Box/OutputBox';
import { GradeWindow } from '../../components/window';

const { TextArea } = Input;

const LabelTypography = styled(Typography)({
    fontSize: '15px',
    fontWeight: 'bold',
});

const KeyInBox = styled(Box)({
    padding: '0px 10px 0 10px',
    height: '200px',
});

export const GradeWindowContainer = () => {
    const navigate = useNavigate();
    const { problemId, studentId } = useParams();

    const [
        execute,
        {
            data: executeResult,
            isLoading: executing,
        },
    ] = useExecuteMutation();
    const {
        data: studentSubmission = {},
        isSuccess: studentSubmissionFetched,
    } = useGetStudentSubmissionQuery(
        { studentId, problemId },
        { skip: studentId === undefined },
    );
    const [
        score,
        {
            isSuccess: scored,
        },
    ] = useScoreMutation();

    const formRef = useRef(null);
    const [form] = Form.useForm();

    const [isOpen, setIsOpen] = useState(false);
    const [toExecuteData, setToExecuteData] = useState({});
    const [toScoreData, setToScoreData] = useState({});

    const language = 'python'; // TODO: get from submission
    const output = (executeResult?.output || [null])[0];

    const handleCodeChange = (value) => {
        setToExecuteData({
            ...toExecuteData,
            code: value,
        });
    };

    const handleInputChange = (value) => {
        setToExecuteData({
            ...toExecuteData,
            input: value,
        });
    };

    const handleScoreInfoChange = (changedValues) => {
        setToScoreData({
            ...toScoreData,
            ...changedValues,
        });
    };

    const run = () => {
        execute({
            code: toExecuteData.code,
            problemId,
            testCase: toExecuteData.input,
            language,
        });
    };

    const handleOk = async () => {
        try {
            await form.validateFields();
        }
        catch (error) {
            return;
        }

        score({
            studentId,
            problemId,
            score: toScoreData.score,
            comment: toScoreData.comment,
        });
    };

    const handleCancel = () => {
        setIsOpen(false);
        navigate(-1);
    };

    useEffect(() => {
        if (studentId !== undefined) {
            setIsOpen(true);
        }
    }, [studentId]);

    useEffect(() => {
        if (studentSubmissionFetched) {
            setToExecuteData({
                code: studentSubmission.code,
                input: '',
            });

            setToScoreData({
                score: studentSubmission.score,
                comment: studentSubmission.comment,
            });
        }
    }, [studentSubmissionFetched]);

    useEffect(() => {
        if (formRef.current) {
            form.setFieldsValue(toScoreData);
        }
    }, [formRef, JSON.stringify(toScoreData)]);

    useEffect(() => {
        if (scored) {
            setIsOpen(false);
            navigate(-1);
        }
    }, [scored]);

    return (
        <GradeWindow
            title={studentSubmission?.student?.username}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Row>
                <Col span={12} style={{ paddingRight: '10px' }}>
                    <MonacoEditor
                        height="330px"
                        defaultLanguage={language}
                        value={toExecuteData?.code}
                        onChange={handleCodeChange}
                    />
                    <KeyInBox>
                        <Form layout="vertical">
                            <Form.Item
                                name="input"
                                label={<LabelTypography>Input</LabelTypography>}
                            >
                                <TextArea
                                    value={toExecuteData?.input}
                                    autoSize={{
                                        minRows: 7,
                                        maxRows: 7,
                                    }}
                                    onChange={(e) => handleInputChange(e.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </KeyInBox>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        margin="12px 10px 0 0"
                    >
                        <Button
                            loading={executing}
                            disabled={executing}
                            onClick={run}
                        >
                            執行
                        </Button>
                    </Box>
                </Col>
                <Col span={12}>
                    <LabelTypography>Output:</LabelTypography>
                    <OutputBox>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: output }} />
                    </OutputBox>
                    <KeyInBox>
                        <Form
                            layout="vertical"
                            onValuesChange={handleScoreInfoChange}
                            form={form}
                            ref={formRef}
                        >
                            <Form.Item
                                name="score"
                                label={<LabelTypography>分數</LabelTypography>}
                                rules={[{ required: true }]}
                            >
                                <Input
                                    value={toScoreData.score || ''}
                                />
                            </Form.Item>
                            <Form.Item
                                name="comment"
                                label={<LabelTypography>評論</LabelTypography>}
                            >
                                <TextArea
                                    value={toScoreData.comment || ''}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 3,
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </KeyInBox>
                </Col>
            </Row>
        </GradeWindow>
    );
};

import { PanelResizeHandle } from 'react-resizable-panels';

import Box from '@mui/material/Box';

export const ResizeHandle = ({ style = {}, direction = 'vertical' }) => (
    <PanelResizeHandle
        style={{
            flex: '0 0 .5em',
            position: 'relative',
            outline: 'none',
            '--background-color': 'transparent',
            ...style,
        }}
    >
        <Box
            sx={{
                position: 'absolute',
                top: '0.25em',
                bottom: '0.25em',
                left: '0.25em',
                right: '0.25em',
                borderRadius: '0.25em',
                backgroundColor: 'var(--background-color)',
                transition: 'background-color 0.2s linear',
            }}
        >
            <svg
                style={{
                    width: '.5em',
                    height: '.5em',
                    position: 'absolute',
                    left: 'calc(50% - 0.25rem)',
                    top: 'calc(50% - 0.25rem)',
                    ...(direction === 'horizontal' && {
                        transform: 'rotate(90deg)',
                    }),
                }}
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
                />
            </svg>
        </Box>
    </PanelResizeHandle>
);

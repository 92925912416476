import {
    forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';

import { CREATE_OPTION_VALUE } from '../../constants';
import { getInitialProblem } from '../../states';
import { CreateProblemWindow } from '../window';

import { Answer } from './Answer';
import { Check } from './Check';
import { Info } from './Info';
import { Option } from './Option';
import { TestCase } from './TestCase';

const getEmptyProblem = () => ({
    ...getInitialProblem(),
    selectedOption: CREATE_OPTION_VALUE.SELF,
});

const splitProblem = (problem) => ({
    optionData: {
        selectedOption: problem?.selectedOption,
        selectedProblem: problem?.selectedProblem,
    },
    infoData: {
        title: problem?.title,
        description: problem?.descriptions?.text,
        inputDescription: problem?.descriptions?.input,
        outputDescription: problem?.descriptions?.output,
        type: problem?.type,
        level: problem?.level,
        tags: problem?.tags,
        isPrivate: problem?.isPrivate,
    },
    testCaseData: {
        examples: problem.examples || [],
        testCases: problem.testCases || [],
    },
    answerData: {
        answer: problem?.answer,
        defaultCode: problem?.defaultCode,
    },

});

export const Creation = forwardRef(({
    defaultData = getEmptyProblem(),
    allProblems,
    allTags,
    onSubmit = () => {},
    icon = <AddIcon />,
    buttonStyle = {},
    disabledColumns = [],
}, ref) => {
    const [problem, setProblem] = useState(defaultData);

    useEffect(() => {
        if (disabledColumns.includes('isPrivate') && !problem.isPrivate) {
            setProblem({
                ...problem,
                isPrivate: true,
            });
        }
    }, [JSON.stringify(problem)]);

    useEffect(() => {
        setProblem({ ...defaultData });
    }, [JSON.stringify(defaultData)]);

    useImperativeHandle(ref, () => ({
        clear: () => {
            setProblem(getEmptyProblem());
        },
    }));

    const options = allProblems.map((p) => ({
        value: p.id,
        label: `${p.id} - ${p.title}`,
    }));

    const {
        optionData,
        infoData,
        testCaseData,
        answerData,
    } = splitProblem(problem);

    const handleOptionChange = (values) => {
        const { selectedOption, selectedProblem } = values;
        if (selectedOption === CREATE_OPTION_VALUE.SELF) {
            setProblem({
                ...getEmptyProblem(),
                selectedOption,
                selectedProblem,
            });
        }
        else if (selectedProblem) {
            const selectedProblemData = allProblems.find((p) => p.id === selectedProblem);
            if (problem.selectedProblem !== selectedProblemData?.id) {
                setProblem({
                    ...selectedProblemData,
                    selectedOption,
                    selectedProblem,
                });
            }
        }
    };

    const handleDataChange = (values) => {
        setProblem({
            ...problem,
            ...values,
        });
    };

    const stepContent = {
        option: (
            <Option
                options={options}
                data={optionData}
                onChange={handleOptionChange}
                disabled={disabledColumns.includes('selectedOption')}
            />
        ),
        info: (
            <Info
                data={infoData}
                tags={allTags}
                onChange={handleDataChange}
                onlyPrivate={disabledColumns.includes('isPrivate')}
            />
        ),
        testcase: (
            <TestCase
                data={testCaseData}
                onChange={handleDataChange}
            />
        ),
        answer: (
            <Answer
                data={answerData}
                onChange={handleDataChange}
            />
        ),
        check: (
            <Check
                data={problem}
                onChange={handleDataChange}
            />
        )
        ,
    };

    const handleSubmit = () => {
        onSubmit(problem);
    };

    return (
        <CreateProblemWindow
            icon={icon}
            buttonStyle={buttonStyle}
            stepContent={stepContent}
            submit={handleSubmit}
            isChecked={problem.checked}
            submitText="儲存"
        />
    );
});

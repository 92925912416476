import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
    Button,
    DatePicker,
    Form,
    Input,
} from 'antd';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AlertWindow } from '../../../../components/window';
import { useCreateAssignmentMutation } from '../../../../store/services/restApi';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Title = styled(Typography)({
    marginTop: 8,
    marginBottom: 8,
    fontWeight: 'bold',
});

export const CreateAssignmentContainer = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [
        createAssignment,
        {
            data: assignment,
            isSuccess: assignmentCreated,
            isLoading: assignmentIsCreating,
            error: createAssignmentError,
        },
    ] = useCreateAssignmentMutation();

    const [showAlert, setShowAlert] = useState(false);
    const [afterClose, setAfterClose] = useState(false);
    const message = assignmentCreated ? '主題建立成功！' : createAssignmentError?.message;

    const onFinish = async (fieldsValue) => {
        const rangeTimeValue = fieldsValue.deadline;
        const values = {
            title: fieldsValue.title,
            description: fieldsValue.description === undefined ? '' : fieldsValue.description,
            startDate: rangeTimeValue[0].format(`YYYY-MM-DD${'T'}HH:mm:ss`),
            endDate: rangeTimeValue[1].format(`YYYY-MM-DD${'T'}HH:mm:ss`),
        };

        await createAssignment({
            courseId,
            ...values,
        });

        setShowAlert(true);
    };

    const handleCloseWindow = () => {
        setShowAlert(false);
        setAfterClose(true);
    };

    useEffect(() => {
        if (afterClose) {
            navigate(`/teacher/courses/${courseId}/${assignment?.id}`);
        }
    }, [afterClose]);

    return (
        <>
            <Form onFinish={onFinish}>
                <Title>主題名稱：</Title>
                <Form.Item
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: '請輸入主題名稱',
                        },
                    ]}
                >
                    <Input size="large" />
                </Form.Item>

                <Title>繳交期限：</Title>
                <Form.Item
                    name="deadline"
                    rules={[
                        {
                            type: 'array',
                            required: true,
                            message: '請選擇繳交期限',
                        },
                    ]}
                >
                    <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} size="large" />
                </Form.Item>

                <Title>備註：</Title>
                <Form.Item name="description">
                    <TextArea
                        placeholder=""
                        autoSize={{ minRows: 5, maxRows: 10 }}
                        size="large"
                    />
                </Form.Item>

                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={assignmentIsCreating}
                    >
                        建立
                    </Button>
                </Box>
            </Form>
            <AlertWindow
                message={message}
                visible={showAlert}
                onOk={handleCloseWindow}
                onCancel={handleCloseWindow}
            />
        </>
    );
};

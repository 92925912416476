import { Alert } from 'antd';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ErrorMessage = ({ message }) => (
    <Box>
        <Typography variant="h6" fontWeight="bold">
            錯誤訊息：
        </Typography>
        <Alert
            message={
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: message }} />
            }
            type="error"
            showIcon
            style={{
                whiteSpace: 'pre-wrap',
            }}
        />
    </Box>
);

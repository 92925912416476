import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { basePalette } from '../../theme/baseTheme';

export const NavButton = ({ text, link, buttonStyle = {} }) => (
    <Typography
        variant="h6"
        display="block"
    >
        <Button
            component={Link}
            to={link}
            sx={{
                color: basePalette.white,
                fontSize: 'inherit',
                ...buttonStyle,
            }}
        >
            {text}
        </Button>
    </Typography>
);

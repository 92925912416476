import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Footer = styled(Box)(({ theme }) => ({
    minHeight: '72px',
    padding: '24px',
    background: theme.palette.common.white,
    fontSize: '14px',
    textAlign: 'center',
}));

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const CodeCoverage = ({ code, report }) => {
    const missingLines = new Set(report?.missingLines);
    const executedLines = new Set(report?.executedLines);
    const partialLines = new Set(report?.partialBranches);

    return (
        <Box>
            <Typography variant="h6" fontWeight="bold">
                程式碼覆蓋率：{report?.score}
            </Typography>
            <SyntaxHighlighter
                language="python"
                wrapLines
                style={vs}
                showLineNumbers
                lineProps={(lineNumber) => {
                    const style = {
                        padding: '2px 0',
                        display: 'block',
                        width: '100%',
                    };
                    if (partialLines.has(lineNumber)) {
                        style.backgroundColor = '#ffffcc';
                    }
                    else if (missingLines.has(lineNumber)) {
                        style.backgroundColor = '#ffcccc';
                    }
                    else if (executedLines.has(lineNumber)) {
                        style.backgroundColor = '#ccffcc';
                    }
                    return { style };
                }}
            >
                {code}
            </SyntaxHighlighter>
        </Box>
    );
};

import { useState } from 'react';

import { Link } from 'react-router-dom';

import {
    Col,
    Rate,
    Row,
    Select,
    Table,
} from 'antd';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { TagFactory } from '../../../components/tag/factories';
import { useGetAllProblemsQuery } from '../../../store/services/restApi';
import { round } from '../../../utils/math';

const FilterContainer = styled(Box)(() => ({
    display: 'inline-block',
    marginRight: '24px',
}));

const levelOptions = [
    {
        value: 1,
        label: '超簡單',
    },
    {
        value: 2,
        label: '簡單',
    },
    {
        value: 3,
        label: '中等',
    },
    {
        value: 4,
        label: '困難',
    },
    {
        value: 5,
        label: '超困難',
    },
];

const typeOptions = [
    {
        value: 'a',
        label: '自動批改',
    },
    {
        value: 'm',
        label: '手動批改',
    },
];

const columns = [
    {
        title: '狀態',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        align: 'center',
        render: (status) => TagFactory.get(status),
    },
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        align: 'center',
    },
    {
        title: '題目',
        dataIndex: 'title',
        key: 'title',
        width: '40%',
        render: (title, record) => (
            <Link to={`/problems/${record.id}`}>{title}</Link>
        ),
    },
    {
        title: '難度',
        dataIndex: 'level',
        key: 'level',
        width: '20%',
        align: 'center',
        render: (level) => (
            <Rate disabled value={level} />
        ),
    },
    {
        title: 'AC率',
        dataIndex: 'acRate',
        key: 'acRate',
        width: '10%',
        align: 'center',
        render: (acRate) => `${round(acRate * 100, 2)}%`,
    },
    {
        title: '批改模式',
        dataIndex: 'type',
        key: 'type',
        width: '15%',
        align: 'center',
        render: (type) => {
            const typeText = type.toUpperCase() === 'A' ? 'AUTO' : 'MANUAL';
            return TagFactory.get(typeText);
        },
    },
];
export const ProblemListContainer = () => {
    const {
        data: allProblems = [],
        isLoading: allProblemsAreFetching,
    } = useGetAllProblemsQuery();

    const allTags = []; // TODO: fetch from backend

    const [level, setLevel] = useState(null);
    const [type, setType] = useState(null);
    const [tagIds, setTagIds] = useState([]);
    const [keyword, setKeyword] = useState('');

    const data = allProblems
        .filter((problem) => (
            (level === null || problem.level === level)
            && (type === null || problem.type === type)
            && (tagIds.length === 0 || tagIds.every((tagId) => problem.tags.includes(tagId)))
            && (keyword === '' || problem.title.includes(keyword))
        ));

    return (
        <>
            <Row>
                <Col span={2} />
                <Col span={20}>
                    <Box style={{ marginBottom: '32px' }}>
                        <FilterContainer>
                            <Select
                                placeholder="難度"
                                options={levelOptions}
                                style={{ width: 120 }}
                                onSelect={(v) => setLevel(v)}
                            />
                        </FilterContainer>
                        <FilterContainer>
                            <Select
                                placeholder="Tag"
                                options={allTags}
                                style={{ width: 120 }}
                                onSelect={(ids) => setTagIds([...ids])}
                            />
                        </FilterContainer>
                        <FilterContainer>
                            <Select
                                placeholder="批改模式"
                                options={typeOptions}
                                style={{ width: 120 }}
                                onSelect={(v) => setType(v)}
                            />
                        </FilterContainer>
                        <FilterContainer>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField
                                    label="搜尋"
                                    size="small"
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                            </Box>
                        </FilterContainer>
                    </Box>
                </Col>
                <Col span={2} />
            </Row>
            <Row>
                <Col span={2} />
                <Col span={20}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={allProblemsAreFetching}
                        rowKey="id"
                    />
                </Col>
                <Col span={2} />
            </Row>
        </>
    );
};

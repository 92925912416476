import { useParams } from 'react-router-dom';

import { List, Typography } from 'antd';

import Card from '@mui/material/Card';

import { useGetCourseDetailQuery } from '../../../store/services/restApi';

const { Title, Text } = Typography;

export const MemberListContainer = () => {
    const { courseId } = useParams();
    const {
        data: courseDetail = {},
        isError: courseDetailFetchError,
    } = useGetCourseDetailQuery({ courseId });

    if (courseDetailFetchError) {
        return <> </>;
    }

    const courseMember = courseDetail.members
        .filter((member) => (member.status === '已加入'))
        .map((member) => ({
            key: member.member.id,
            title: member.name,
        }));

    return (
        <Card
            sx={{
                padding: '8px 24px',
                height: '100%',
            }}
        >
            <Title level={3}>成員名單</Title>
            <List
                size="small"
                dataSource={courseMember}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            description={(
                                <Text>
                                    { item.title}
                                </Text>
                            )}
                        />
                    </List.Item>
                )}
                style={{ height: '360px', overflowY: 'auto' }}
            />

        </Card>
    );
};

import { useEffect, useRef, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';

import {
    Button,
    Col,
    Input,
    Radio,
    Row,
    Space,
    Spin,
    Tooltip,
} from 'antd';
import {
    CheckCircleTwoTone,
    CopyTwoTone,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AlertWindow } from '../../../../components/window';
import {
    useGetCourseDetailQuery,
    useUpdateCourseMutation,
} from '../../../../store/services/restApi';
import { basePalette } from '../../../../theme/baseTheme';
import { MemberTransferTable } from '../../components/transfer';

const Title = styled(Typography)({
    marginTop: 8,
    marginBottom: 8,
    fontWeight: 'bold',
});

const settingInfo = (
    <Box style={{ alignItems: 'center' }}>
        <Typography>
            公開：成員{' '}
            <Space style={{ fontWeight: 'bold', fontSize: '16px' }}>無須</Space>
            {' '}經過審核
        </Typography>
        <Typography>
            私人：成員{' '}
            <Space style={{ fontWeight: 'bold', fontSize: '16px' }}>須</Space>
            {' '}經過審核
        </Typography>
    </Box>
);

const locale = {
    itemUnit: '位',
    itemsUnit: '位',
    notFoundContent: '找不到使用者',
    searchPlaceholder: '請輸入名稱',
    remove: '刪除',
    selectAll: '全選',
    selectCurrent: '選擇當前頁',
    selectInvert: '反選',
    removeAll: '刪除所有',
    removeCurrent: '刪除當前頁',
};

export const CourseContainer = () => {
    const transferRef = useRef(null);
    const { courseId } = useParams();
    const {
        data: courseDetail = {},
        isSuccess: courseDetailIsFetched,
        isLoading: courseDetailIsFetching,
        refetch: refetchCourseDetail,
    } = useGetCourseDetailQuery({ courseId });

    const [
        updateCourse,
        {
            error: courseUpdateError,
            isFetching: courseIsUpdating,
            isSuccess: courseIsUpdated,
            isError: courseIsUpdateError,
        },
    ] = useUpdateCourseMutation();

    const [memberStatus, setMemberStatus] = useState({});
    const [toUpdateCourse, setToUpdateCourse] = useState({});
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [showAlertWindow, setShowAlertWindow] = useState(false);

    const allMembers = (courseDetail.members || [])
        .filter((member) => member.role !== '教師')
        .map((member) => ({
            id: member.member.id,
            name: member.name,
            status: member.status,
            role: member.role,
            key: member.member.id,
        }));

    let updateCourseMessage = '';
    if (courseIsUpdated) {
        updateCourseMessage = '課程資訊已更新！';
    }
    else if (courseIsUpdateError) {
        updateCourseMessage = courseUpdateError.message;
    }

    const handleCourseNameChange = (e) => {
        setToUpdateCourse({
            ...toUpdateCourse,
            name: e.target.value,
        });
    };

    const handleIsPrivateChange = (e) => {
        setToUpdateCourse({
            ...toUpdateCourse,
            isPrivate: e.target.value,
        });
    };

    const handleMemberStatusChange = (members) => {
        setMemberStatus(members.map((member) => ({
            member: member.id,
            status: member.status,
        })));
    };

    const handleUpdateCourse = async () => {
        await updateCourse({
            courseId,
            name: toUpdateCourse.name,
            isPrivate: toUpdateCourse.isPrivate,
            memberStatus,
        });

        setShowAlertWindow(true);
    };

    const restoreUpdateCourse = () => {
        setToUpdateCourse({
            ...courseDetail,
        });
        transferRef.current.restore();
    };

    useEffect(() => {
        refetchCourseDetail({ courseId });
    }, []);

    useEffect(() => {
        setToUpdateCourse({
            ...courseDetail,
        });
    }, [JSON.stringify(courseDetail)]);

    useEffect(() => {
        if (courseDetailIsFetched) {
            const courseAreEqual = isEqual(toUpdateCourse, courseDetail);
            const memberStatusIsEmpty = memberStatus.length === 0;

            setShowSaveButton(!courseAreEqual || !memberStatusIsEmpty);
        }
    }, [
        JSON.stringify(toUpdateCourse),
        JSON.stringify(courseDetail),
        JSON.stringify(memberStatus),
    ]);

    const courseLink = 'https://translate.google.com.tw/?hl=zh-TW';
    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };
    // TODO: 取得真正的連結後再顯示
    // eslint-disable-next-line no-unused-vars
    const courseLinkBlock = (
        <Row align="middle">
            <Space style={{ marginTop: 14 }}>
                <Title>
                    邀請連結：
                </Title>
                <Typography>{courseLink}</Typography>
                <CopyToClipboard text={courseLink} onCopy={handleCopy}>
                    <Space style={{ cursor: 'pointer', fontSize: '18px' }}>
                        {copied ? <CheckCircleTwoTone /> : <CopyTwoTone />}
                    </Space>
                </CopyToClipboard>
            </Space>
        </Row>
    );

    return (
        <Spin spinning={courseDetailIsFetching}>
            <Row>
                <Col span={7} />
                <Col span={10}>
                    <Title>課程名稱：</Title>
                    <Input
                        size="large"
                        value={toUpdateCourse?.name}
                        onChange={handleCourseNameChange}
                    />
                    {/* {courseLinkBlock} */}
                    <Space>
                        <Title>設定</Title>
                        <Tooltip title={settingInfo} placement="top">
                            <QuestionCircleOutlined />
                        </Tooltip>
                        <Title>：</Title>
                        <Radio.Group
                            name="setting"
                            value={toUpdateCourse?.isPrivate}
                            onChange={handleIsPrivateChange}
                        >
                            <Radio value={false}>公開</Radio>
                            <Radio value>私人</Radio>
                        </Radio.Group>
                    </Space>
                </Col>
                <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        showSaveButton
                        && (
                            <>
                                <Button
                                    type="primary"
                                    style={{ marginRight: 10 }}
                                    onClick={handleUpdateCourse}
                                    loading={courseIsUpdating}
                                    disabled={courseIsUpdating}
                                >
                                    儲存
                                </Button>
                                <Button
                                    danger
                                    onClick={restoreUpdateCourse}
                                >
                                    取消
                                </Button>
                            </>
                        )
                    }
                </Col>
            </Row>
            <Row align="middle">
                <Col span={5} />
                <Col span={14}>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', visibility: 'hidden' }}>
                        <Button>新增成員</Button>
                    </Box>
                    <Row align="middle">
                        <Col span={12} style={{ textAlign: 'center' }}>
                            <Title>待審核成員</Title>
                        </Col>
                        <Col span={12} style={{ textAlign: 'center' }}>
                            <Title>所有成員</Title>
                        </Col>
                    </Row>
                    <MemberTransferTable
                        data={allMembers}
                        locale={locale}
                        listStyle={{
                            width: '100%',
                            height: 350,
                            backgroundColor: basePalette.white,
                        }}
                        onChange={handleMemberStatusChange}
                        ref={transferRef}
                    />
                </Col>
                <Col span={5} />
            </Row>
            <AlertWindow
                message={updateCourseMessage}
                visible={showAlertWindow}
                onOk={() => {
                    setShowAlertWindow(false);
                }}
                onCancel={() => {
                    setShowAlertWindow(false);
                }}
            />
        </Spin>
    );
};

import { useEffect } from 'react';

import {
    Col,
    Row,
    Tree,
} from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
    check as utilCheck,
    recursiveCheck,
} from '../../../../utils/check';

const Title = styled(Typography)({
    fontSize: '20px',
    display: 'inline',
    marginLeft: '-24px',
    fontWeight: 'bold',
});

const NodeTitle = styled(Title)({
    fontSize: '16px',
    fontWeight: 'normal',
});

const getCheckIcon = (checked) => (
    checked
        ? <CheckCircleOutlined style={{ color: '#52c41a', marginLeft: '-56px' }} />
        : <CloseCircleOutlined style={{ color: '#ff4d4f', marginLeft: '-56px' }} />
);

const getNodeData = (title, key, data, check = utilCheck) => {
    const checked = check(data);
    const icon = getCheckIcon(checked);

    return {
        title: (
            <NodeTitle>
                {title}
            </NodeTitle>
        ),
        key,
        icon,
        isChecked: checked,
    };
};

const getTreeDataList = (problem) => {
    const result = [
        [
            {
                title: <Title>出題方式</Title>,
                key: 'option',
                children: [
                    getNodeData('選擇', 'option-select', problem.selectedOption),
                ],
            },
        ],
        [
            {
                title: <Title>基本資訊</Title>,
                key: 'info',
                children: [
                    getNodeData('標題', 'info-title', problem.title),
                    getNodeData('描述', 'info-description', problem.descriptions.text),
                    getNodeData('輸入說明', 'info-inputDescription', problem.descriptions.input),
                    getNodeData('輸出說明', 'info-outputDescription', problem.descriptions.output),
                    getNodeData('題目類型', 'info-type', problem.type),
                    getNodeData('難度', 'info-level', problem.level),
                    getNodeData('標籤', 'info-tags', problem.tags, () => true),
                ],
            },
        ],
        [
            {
                title: <Title>題目測資</Title>,
                key: 'testcase',
                children: [
                    getNodeData('範例', 'testcase-examples', problem.examples, recursiveCheck),
                    getNodeData('測資', 'testcase-testCases', problem.testCases, recursiveCheck),
                ],
            },
        ],
        [
            {
                title: <Title>參考解答</Title>,
                key: 'answer',
                children: [
                    getNodeData('解答', 'answer-answer', problem.answer),
                    getNodeData('預設程式碼', 'answer-defaultCode', problem.defaultCode),
                ],
            },
        ],
    ];

    result.map((treeData) => {
        const node = treeData[0];
        const isChecked = node.children.every((child) => (child.isChecked));
        node.icon = getCheckIcon(isChecked);
        node.isChecked = isChecked;
        return treeData;
    });

    return result;
};

export const Check = ({ data, onChange = () => {} }) => {
    const treeDataList = getTreeDataList(data);
    const checked = treeDataList.every((treeData) => treeData[0].isChecked);

    useEffect(() => {
        onChange({ checked });
    }, [checked]);

    return (
        <Row
            style={{
                marginTop: '24px',
            }}
        >
            {
                treeDataList.map((treeData) => (
                    <Col span={6} key={treeData[0].key}>
                        <Tree
                            defaultExpandAll
                            treeData={treeData}
                            selectable={false}
                            switcherIcon={<> </>}
                            showIcon
                        />
                    </Col>
                ))
            }
        </Row>
    );
};

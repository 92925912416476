import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

const Header = ({ children }) => (
    <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" sx={{ zIndex: 999 }}>
            <Toolbar>
                {children}
            </Toolbar>
        </AppBar>
    </Box>
);

const Content = styled(Box)(({ theme }) => ({
    minHeight: 'calc(100vh - 64px - 72px)',
    marginTop: '64px',
    padding: '24px',
    background: theme.palette.common.white,
}));

const Footer = styled(Box)(({ theme }) => ({
    minHeight: '72px',
    padding: '24px',
    background: theme.palette.common.white,
    fontSize: '14px',
    textAlign: 'center',
}));

export const Layout = { Header, Content, Footer };

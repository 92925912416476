import { useEffect, useState } from 'react';

import { message } from 'antd';

import { CreateCourseWindow } from '../../../components/window';
import { useCreateCourseMutation } from '../../../store/services/restApi';

export const CreateCourseWindowContainer = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [createCourse, {
        error: courseCreateError,
        isSuccess: courseIsCreated,
        isLoading: courseIsCreating,
        isError: courseIsCreateError,
    }] = useCreateCourseMutation();

    const [visible, setVisible] = useState(false);

    const success = () => {
        messageApi.open({
            type: 'success',
            content: '建立成功！',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: courseCreateError.message,
        });
    };

    useEffect(() => {
        if (courseIsCreated) {
            setVisible(false);
            success();
        }
        if (courseIsCreateError) {
            setVisible(true);
            error();
        }
        if (courseIsCreating) {
            setVisible(true);
        }
    }, [courseIsCreated, courseIsCreateError, courseIsCreating]);

    return (
        <>
            {contextHolder}
            <CreateCourseWindow
                onSubmit={(data) => createCourse(data)}
                reset={courseIsCreated}
                visible={visible}
            />
        </>
    );
};

import { useParams } from 'react-router-dom';

import { useGetProblemByIdQuery } from '../../../store/services/restApi';
import { Description } from '../components/description';

export const DescriptionContainer = () => {
    const { problemId } = useParams();

    const {
        data: problem = {},
    } = useGetProblemByIdQuery({ problemId });

    return (
        <Description
            id={problemId}
            title={problem?.title}
            descriptions={problem.descriptions || {}}
            examples={problem.examples || []}
            tags={problem.tags || []}
            level={problem?.level}
        />
    );
};

import { useEffect, useState } from 'react';

import {
    Button,
    Modal,
} from 'antd';

export const BaseWindow = ({
    title,
    children,
    okButtonProps = {},
    onOk = () => {},
    onCancel = () => {},
    windowBodyStyle = {},
    visible = false,
}) => {
    const [isOpen, setIsOpen] = useState(visible);

    useEffect(() => {
        setIsOpen(visible);
    }, [visible]);

    const openWindow = () => {
        setIsOpen(true);
    };
    const closeWindow = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Button onClick={openWindow}>
                {title}
            </Button>
            <Modal
                title={title}
                open={isOpen}
                onOk={onOk}
                okButtonProps={okButtonProps}
                onCancel={() => {
                    onCancel();
                    closeWindow();
                }}
                bodyStyle={windowBodyStyle}
            >
                {children}
            </Modal>
        </>
    );
};

import { Button } from 'antd';

import Box from '@mui/material/Box';

export const MiddleFooter = ({ onBack = () => {}, onNext = () => {} }) => (
    <Box
        style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}
    >
        <Button onClick={onBack}>
            上一步
        </Button>
        <Button
            type="primary"
            onClick={onNext}
        >
            下一步
        </Button>
    </Box>
);

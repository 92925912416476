import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import { OpenEduLoginButton } from '../../../components/button/OpenEduLoginButton';
import { REQUEST_STATUS } from '../../../constants';
import ROUTES from '../../../router/routes';
import { selectLoginStatus } from '../../../store/slices/authSlice';

export const LoginContainer = () => {
    const navigate = useNavigate();

    const status = useSelector(selectLoginStatus);

    useEffect(() => {
        if (status === REQUEST_STATUS.SUCCESS) {
            navigate(ROUTES.HOME.link);
        }
    }, [status]);

    return (
        <Box margin="16px 0">
            <OpenEduLoginButton
                color="primary"
            />
        </Box>
    );
};

import {
    forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';

import { Transfer } from 'antd';

export const MemberTransferTable = forwardRef((
    {
        data,
        locale,
        listStyle,
        onChange = () => {},
    },
    ref,
) => {
    const allMembers = data.map((item) => ({
        key: item.id,
        title: item.name,
        description: item.name,
        chosen: item.status === '已加入',
    }));

    const [memberData, setMemberData] = useState([]);

    const initialMemberStatus = data
        .map((item) => ({
            id: item.key,
            title: item.name,
            description: item.name,
            status: item.status,
            key: item.key,
        }));

    const [membersInCourse, setMembersInCourse] = useState([]);

    const updateMemberStatus = (toUpdateMembers) => {
        const newStatus = [];

        data.forEach((member) => {
            if (toUpdateMembers.includes(member.id)) {
                if (member.status === '待審核' || member.status === '已拒絕') {
                    newStatus.push({ id: member.id, status: '已加入' });
                }
            }
            else if (member.status === '已加入') {
                newStatus.push({ id: member.id, status: '已拒絕' });
            }
        });

        return newStatus;
    };

    const filterOption = (inputValue, option) => option.description.indexOf(inputValue) > -1;

    const handleMemberStatusChange = () => {
        const newStatus = updateMemberStatus(membersInCourse);
        onChange(newStatus);
    };

    const handleTransferChange = (newTargetKeys) => {
        setMembersInCourse(() => [...newTargetKeys]);
    };

    useImperativeHandle(ref, () => ({
        restore: () => {
            setMembersInCourse(
                initialMemberStatus
                    .filter((item) => item.status === '已加入')
                    .map((item) => item.key),
            );
            setMemberData([...initialMemberStatus]);
        },
    }));

    useEffect(() => {
        if (allMembers.length > 0) {
            setMembersInCourse(allMembers.filter((item) => item.chosen).map((item) => item.key));
            setMemberData([...allMembers]);
        }
    }, [JSON.stringify(allMembers)]);

    useEffect(() => {
        handleMemberStatusChange();
    }, [membersInCourse]);

    return (
        <Transfer
            dataSource={memberData}
            showSearch
            oneWay
            filterOption={filterOption}
            targetKeys={membersInCourse}
            onChange={handleTransferChange}
            render={(item) => item.title}
            listStyle={listStyle}
            locale={locale}
        />
    );
});

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Console = styled(
    Box,
    { shouldForwardProp: (prop) => prop !== 'visible' },
)(({ visible }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    border: '1px solid #f0f0f0',
    display: 'none',
    ...(visible && {
        display: 'block',
    }),
}));

import { useEffect, useState } from 'react';

import { Steps } from 'antd';

import Box from '@mui/material/Box';

import { Capture } from './Capture';
import { Editor } from './Editor';

export const CodeCapture = ({ codeOptions = [], onCapture = () => {} }) => {
    const [image, setImage] = useState(null);
    const [current, setCurrent] = useState(0);

    const handleCapture = (data) => {
        onCapture(data);
    };

    const stepItems = [
        {
            title: '截圖',
            content: (
                <Capture
                    codeOptions={codeOptions}
                    onCapture={setImage}
                />
            ),
        },
        {
            title: '編輯',
            content: (
                <Editor
                    image={image}
                    onSave={handleCapture}
                />
            ),
            disabled: !image,
        },
    ];

    const handleChange = (value) => {
        setCurrent(value);
    };

    useEffect(() => {
        if (image) {
            setCurrent(1);
        }
    }, [image]);

    return (
        <Box
            minWidth="800px"
            maxWidth="1200px"
            padding="16px"
        >
            <Steps
                onChange={handleChange}
                current={current}
                items={stepItems}
            />
            <Box marginTop="8px">
                {
                    stepItems[current].content
                }
            </Box>
        </Box>
    );
};

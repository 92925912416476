import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import {
    useGetCandidateProblemsQuery,
    useUpdateProblemMutation,
} from '../../../store/services/restApi';
import { Creation } from '../components/creation';
import { CREATE_OPTION_VALUE } from '../constants';

export const UpdateProblemContainer = ({ problemId }) => {
    const [updateProblem] = useUpdateProblemMutation();

    const {
        data: candidateProblems = [],
    } = useGetCandidateProblemsQuery();

    const problemData = {
        ...candidateProblems?.find((p) => p.id === problemId),
        selectedOption: CREATE_OPTION_VALUE.CHOOSE,
        selectedProblem: problemId,
    };

    const tags = []; // TODO: fetch from backend

    const handleSubmit = (problem) => {
        updateProblem({ problemId, ...problem });
    };

    return (
        <Creation
            icon={<ModeEditOutlineIcon fontSize="16" />}
            buttonStyle={{
                width: '28px',
                height: '24px',
                padding: '2px 0',
            }}
            defaultData={problemData}
            allProblems={candidateProblems}
            allTags={tags}
            onSubmit={handleSubmit}
        />
    );
};

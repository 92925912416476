import { useParams } from 'react-router-dom';

import {
    ArticleContainer,
    EditorContainer,
    ReportContainer,
} from '../../containers';

const RIGHT_PAGE_OPTIONS = {
    EDITOR: 'editor',
    REPORT: 'report',
    ARTICLE: 'article',
};

const getRightPage = (problemId, type, dataId) => {
    switch (type) {
        case RIGHT_PAGE_OPTIONS.REPORT:
            return <ReportContainer id={dataId} />;
        case RIGHT_PAGE_OPTIONS.ARTICLE:
            return <ArticleContainer id={dataId} />;
        default:
            return (
                <EditorContainer id={problemId} />
            );
    }
};

export const RightPageContainer = () => {
    const { problemId, right, dataId } = useParams();

    return getRightPage(problemId, right, dataId);
};

import { useParams } from 'react-router-dom';

import { Typography } from 'antd';

import Card from '@mui/material/Card';

import {
    useGetCourseDetailQuery,
    useGetMyDetailQuery,
} from '../../../store/services/restApi';

const { Title, Paragraph } = Typography;

export const CourseInfoContainer = () => {
    const { courseId } = useParams();

    const {
        data: courseDetail = {},
        isError: courseDetailFetchError,
    } = useGetCourseDetailQuery({ courseId });

    const { data: userDetail = {} } = useGetMyDetailQuery();

    const username = userDetail?.username || '';

    if (courseDetailFetchError) {
        return <> </>;
    }

    const displayName = courseDetail.members.filter((member) => (
        member.member.username === username
    ))[0]?.name;

    return (
        <Card style={{ textAlign: 'center' }}>
            <Title level={3}>{courseDetail.name}</Title>
            <Paragraph>教師: {courseDetail.owner.username}</Paragraph>
            <Paragraph>名稱: {displayName}</Paragraph>
        </Card>
    );
};

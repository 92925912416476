import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const TestCase = ({ testCase }) => (
    <Box>
        <Typography variant="h6" fontWeight="bold">
            你的測資：
        </Typography>
        <SyntaxHighlighter language="text" style={vs}>
            {testCase}
        </SyntaxHighlighter>
    </Box>
);

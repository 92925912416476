import { CodeBlock, CopyBlock, googlecode } from 'react-code-blocks';

import Box from '@mui/material/Box';

export const TestCaseBlock = ({ text, style = {}, isInput = true }) => (
    <Box
        style={{
            border: '1px solid #e0e0e0',
            ...style,
        }}
        sx={{
            '& button': {
                border: '1px solid #ADADAD',
                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                padding: '0.5rem',
                cursor: 'pointer',
            },
        }}
    >
        {
            isInput
                ? (
                    <CopyBlock
                        text={text}
                        language="text"
                        showLineNumbers={false}
                        theme={googlecode}
                        wrapLines
                        codeBlock
                    />
                )
                : (
                    <CodeBlock
                        text={text}
                        language="text"
                        showLineNumbers={false}
                        theme={googlecode}
                        wrapLines
                        codeBlock
                    />
                )
        }
    </Box>
);

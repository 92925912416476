import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const MutationScore = ({ report }) => (
    <Box>
        <Typography variant="h6" fontWeight="bold">
            變異分數：{report?.score}（{report?.killed} / {report?.all}）
        </Typography>
    </Box>
);

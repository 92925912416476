import {
    Col,
    Row,
} from 'antd';

import { BaseTestCaseBlock } from './BaseTestCaseBlock';
import { InputBlock } from './InputBlock';
import { OutputBlock } from './OutputBlock';

export const ExampleTestCaseBlock = ({
    index = 0,
    title = '',
    block = {},
    onRemove = () => {},
    onInputChange = () => {},
    onOutputChange = () => {},
}) => {
    const content = (
        <Row>
            <Col span={12} style={{ marginTop: 5, paddingRight: 2 }}>
                <InputBlock
                    name={block.key}
                    onChange={onInputChange}
                />
            </Col>
            <Col span={12} style={{ marginTop: 5, paddingLeft: 2 }}>
                <OutputBlock
                    name={block.key}
                    onChange={onOutputChange}
                />
            </Col>
        </Row>
    );
    return (
        <BaseTestCaseBlock
            title={title}
            content={content}
            onRemove={() => {
                onRemove(block.key, index);
            }}
        />
    );
};

import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';

export const Editor = ({ image, onSave = () => {} }) => (
    <FilerobotImageEditor
        source={image}
        annotationsCommon={{
            fill: '#ff0000',
        }}
        onBeforeSave={() => false}
        onSave={(data) => {
            onSave(data.imageBase64);
        }}
        Text={{ text: 'text' }}
        Rotate={{
            angle: 90,
            componentType: 'slider',
        }}
        tabsIds={[
            TABS.ADJUST,
            TABS.ANNOTATE,
            TABS.WATERMARK,
        ]}
        defaultTabId={TABS.ANNOTATE}
        defaultToolId={TOOLS.RECT}
    />
);

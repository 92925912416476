export const TAG_LABEL = {
    // submit status
    AC: 'Accepted',
    CE: 'Compile Error',
    PA: 'Partial Accepted',
    RE: 'Runtime Error',
    TLE: 'Time Limit Exceeded',
    WA: 'Wrong Answer',

    // run status
    SUCCESS: '執行成功',
    ERROR: '執行失敗',

    // problem type
    AUTO: '自動批改',
    MANUAL: '人工批改',

    // message
    HAS_SUBMIT: '已繳交',
};

import { forwardRef, useImperativeHandle, useState } from 'react';

import {
    Button,
    Modal,
} from 'antd';

export const CreateArticleWindow = forwardRef(({
    children,
    onCreate = () => {},
}, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const openWindow = () => {
        setIsOpen(true);
    };

    const closeWindow = () => {
        setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
        close: () => {
            closeWindow();
        },
    }));

    return (
        <>
            <Button
                onClick={openWindow}
            >
                建立文章
            </Button>
            <Modal
                open={isOpen}
                onOk={onCreate}
                onCancel={closeWindow}
                width="95%"
                bodyStyle={{
                    height: '80vh',
                    padding: '16px',
                }}
                style={{
                    top: 24,
                }}
            >
                {children}
            </Modal>
        </>
    );
});

import '@fontsource/nunito';

export const basePalette = {
    primary_main: '#4A5EA3',
    primary_light: '#889EE0',
    primary_dark: '#293767',

    secondary_main: '#FE8769',
    secondary_light: '#FE9F87',
    secondary_dark: '#B15E49',

    error_main: '#FF0000',
    error_light: '#FF3333',
    error_dark: '#B20000',

    warning_main: '#ED6C02',
    warning_light: '#FF9800',
    warning_dark: '#E65100',

    success_main: '#2E7D32',
    success_light: '#41BD44',
    success_dark: '#1B5E20',

    white: '#FFFFFF',
    black: '#000000',
    tan_0: '#E7D6C9',
    hover: 'rgba(0, 0, 0, 0.5)',
};

// light mode
export const mode = 'light';

export const fontFamily = 'nunito';

import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { REQUEST_STATUS } from '../../../constants';
import { AiAssistant } from '../components/ai-assistant';
import {
    getRecordFeedbackHistory,
    requestRecordFeedback,
    selectRecordFeedbackHistory,
    selectRecordFeedbackHistoryStatus,
    selectRequestRecordFeedbackRecord,
    selectRequestRecordFeedbackResult,
    selectRequestRecordFeedbackStatus,
} from '../problemSlice';

export const RecordAiAssistantContainer = ({ recordId, type }) => {
    const dispatch = useDispatch();
    // Feedback
    const requestFeedbackStatus = useSelector(selectRequestRecordFeedbackStatus);
    const requestFeedbackRecord = useSelector(selectRequestRecordFeedbackRecord);
    const requestFeedbackResult = useSelector(selectRequestRecordFeedbackResult);
    // History
    const recordFeedbackHistoryStatus = useSelector(selectRecordFeedbackHistoryStatus);
    const recordFeedbackHistory = useSelector(selectRecordFeedbackHistory);

    // state
    const loading = requestFeedbackRecord === recordId && (
        requestFeedbackStatus === REQUEST_STATUS.LOADING
        || recordFeedbackHistoryStatus === REQUEST_STATUS.LOADING
    );
    const error = requestFeedbackRecord === recordId && (
        requestFeedbackStatus === REQUEST_STATUS.ERROR
        || recordFeedbackHistoryStatus === REQUEST_STATUS.ERROR
    );
    const chatRecord = requestFeedbackRecord === recordId && (
        requestFeedbackStatus === REQUEST_STATUS.SUCCESS
    ) ? requestFeedbackResult?.message : recordFeedbackHistory?.message;

    // Fetch record feedback history
    useEffect(() => {
        dispatch(getRecordFeedbackHistory({ recordId, type }));
    }, [recordId]);

    const handleRequestFeedback = (message = '') => {
        if (message) {
            dispatch(requestRecordFeedback({ recordId, type, message }));
        }
        else {
            dispatch(requestRecordFeedback({ recordId, type }));
        }
    };

    return (
        <AiAssistant
            onSend={handleRequestFeedback}
            chatRecord={chatRecord}
            loading={loading}
            error={error}
        />
    );
};

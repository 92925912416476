import axios from 'axios';

import { API_URLS } from '../../constants/service';

export const sendLoginRequest = ({ username, password }) => (
    axios.post(`${API_URLS.REST}/user/login/`, { username, password })
);

export const sendThirdPartyLoginRequest = ({ code }) => axios.post(`${API_URLS.REST}/user/third_party_login/`, { code });

export const sendVerifyTokenRequest = ({ token }) => (
    axios.post(`${API_URLS.REST}/user/verify/`, { token })
);

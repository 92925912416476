import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import ROUTES from '../../../router/routes';
import { imgurApi } from '../../../store/services/imgurApi';
import { restApi } from '../../../store/services/restApi';
import { clearStates as clearAuthState } from '../../../store/slices/authSlice';
import { Cookie } from '../../../utils/cookie';
import { clearStates as clearProblemState } from '../../problem/problemSlice';

export const LogoutPage = () => {
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        dispatch(restApi.util.resetApiState());
        dispatch(imgurApi.util.resetApiState());
        dispatch(clearAuthState());
        dispatch(clearProblemState());

        Cookie.remove('token');
        Cookie.remove('csrftoken');
        Cookie.remove('sessionid');

        setSuccess(true);
    }, []);

    return success && (
        <Navigate to={ROUTES.HOME.link} />
    );
};

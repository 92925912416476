import { basePalette } from './baseTheme';

const antdTheme = {
    token: {
        colorPrimary: basePalette.primary_main,
        colorSuccess: basePalette.success_light,
        colorBorder: '#ADADAD',
        colorTextPlaceholder: '#7B7B7B',
    },
};

export default antdTheme;

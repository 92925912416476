import { useEffect, useState } from 'react';

import {
    Col,
    Form,
    Input,
    Row,
} from 'antd';

const { TextArea } = Input;

export const Answer = ({ data, onChange = () => {} }) => {
    const [answer, setAnswer] = useState(data.answer);
    const [defaultCode, setDefaultCode] = useState(data.defaultCode);

    const onAnswerChange = (e) => {
        setAnswer(e.target.value);
    };

    const onDefaultCodeChange = (e) => {
        setDefaultCode(e.target.value);
    };

    useEffect(() => {
        onChange({ answer, defaultCode });
    }, [answer, defaultCode]);

    return (
        <Form
            name="answerForm"
            layout="vertical"
            style={{ paddingTop: '2%' }}
            initialValues={data}
        >
            <Row>
                <Col span={12} style={{ paddingRight: 8 }}>
                    <Form.Item
                        label="解答"
                        name="answer"
                        rules={[{ required: true, message: '請輸入解答' }]}
                        onChange={onAnswerChange}
                    >
                        <TextArea
                            autoSize={{ minRows: 14, maxRows: 14 }}
                            placeholder="請輸入解答..."
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: 8 }}>
                    <Form.Item
                        label="預設程式碼"
                        name="defaultCode"
                        tooltip="預設程式碼會顯示在編輯器中，供學生參考。"
                        onChange={onDefaultCodeChange}
                    >
                        <TextArea
                            autoSize={{ minRows: 14, maxRows: 14 }}
                            placeholder="請輸入預設程式碼..."
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

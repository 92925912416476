import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Spinner = ({ size = 24 }) => {
    const icon = (
        <LoadingOutlined
            style={{ fontSize: size }}
            spin
        />
    );
    return (
        <Spin indicator={icon} />
    );
};

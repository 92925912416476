import { Modal } from 'antd';

export const GradeWindow = ({
    title,
    open,
    children,
    onOk = () => {},
    onCancel = () => {},
}) => (
    <Modal
        title={title}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        destroyOnClose
        width={1300}
        centered
        bodyStyle={{
            height: '530px',
        }}
        okText="送出"
        cancelText="取消"
    >
        {children}
    </Modal>
);

import { useState } from 'react';

import { ScreenCapture } from 'react-screen-capture';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import { Button } from 'antd';

import { CodeOption } from '../option';

export const Capture = ({ codeOptions = [], onCapture = () => {} }) => {
    const [code, setCode] = useState('');

    const handleSelect = (value) => {
        setCode(value);
    };

    return (
        <ScreenCapture
            onEndCapture={onCapture}
        >
            {
                ({ onStartCapture }) => (
                    <>
                        <CodeOption
                            data={codeOptions}
                            onSelect={handleSelect}
                        />
                        <Button
                            onClick={onStartCapture}
                        >
                            選取
                        </Button>
                        <SyntaxHighlighter
                            language="python"
                            wrapLines
                            style={vs}
                            showLineNumbers
                            customStyle={{
                                fontSize: '16px',
                                maxHeight: '400px',
                            }}
                        >
                            {code}
                        </SyntaxHighlighter>
                    </>
                )
            }
        </ScreenCapture>
    );
};

import {
    Col,
    Form,
    Input,
    Row,
} from 'antd';

import { BaseTestCaseBlock } from './BaseTestCaseBlock';
import { InputBlock } from './InputBlock';
import { OutputBlock } from './OutputBlock';

export const HiddenTestCaseBlock = ({
    index = 0,
    title = '',
    block = {},
    onRemove = () => {},
    onHintChange = () => {},
    onInputChange = () => {},
    onOutputChange = () => {},
}) => {
    const content = (
        <>
            <Form.Item
                label="提示"
                name={`hint${block.key}`}
                rules={[{ required: true, message: '請輸入提示' }]}
                onChange={onHintChange}
            >
                <Input />
            </Form.Item>
            <Row>
                <Col span={12} style={{ marginTop: 5, paddingRight: 2 }}>
                    <InputBlock
                        name={block.key}
                        onChange={onInputChange}
                    />
                </Col>
                <Col span={12} style={{ marginTop: 5, paddingLeft: 2 }}>
                    <OutputBlock
                        name={block.key}
                        onChange={onOutputChange}
                    />
                </Col>
            </Row>
        </>
    );
    return (
        <BaseTestCaseBlock
            title={title}
            content={content}
            onRemove={() => {
                onRemove(block.key, index);
            }}
        />
    );
};

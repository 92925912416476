import Box from '@mui/material/Box';

import { NavButton } from '../../button';

export const MainNavLink = ({ items }) => (
    <Box
        flexGrow={1}
        display={{
            xs: 'none',
            md: 'flex',
        }}
    >
        {items.map(({ text, link }) => (
            <NavButton text={text} link={link} key={text} />
        ))}
    </Box>
);

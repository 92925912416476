import {
    Form,
    Input,
} from 'antd';

const { TextArea } = Input;

export const InputBlock = ({ name = '', onChange = () => {} }) => (
    <Form.Item
        label="Input"
        name={`input${name}`}
        rules={[{ required: true, message: '請輸入 Input' }]}
        onChange={onChange}
    >
        <TextArea
            placeholder="請輸入 Input..."
            autoSize={{
                minRows: 5,
                maxRows: 5,
            }}
        />
    </Form.Item>
);

import {
    Form,
    Input,
} from 'antd';

const { TextArea } = Input;

export const OutputBlock = ({ name = '', onChange = () => {} }) => (
    <Form.Item
        label="Output"
        name={`output${name}`}
        rules={[{ required: true, message: '請輸入 Output' }]}
        onChange={onChange}
    >
        <TextArea
            placeholder="請輸入 Output..."
            autoSize={{
                minRows: 5,
                maxRows: 5,
            }}
        />
    </Form.Item>
);

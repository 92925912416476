import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import {
    Col,
    Row,
} from 'antd';

import { withAuth, withMemberInCourse } from '../../../../hocs';
import { useGetCourseDetailQuery } from '../../../../store/services/restApi';
import { BaseLoadingPage } from '../../../../template/loading';
import { BaseTemplate } from '../../../../template/pages/with-hocs';
import {
    AssignmentTabsContainer,
    CourseInfoContainer,
    MemberListContainer,
} from '../../containers';

const CoursePage = () => {
    const { courseId } = useParams();
    const {
        isLoading: courseDetailIsFetching,
        refetch: refetchCourseDetail,
    } = useGetCourseDetailQuery({ courseId });

    useEffect(() => {
        refetchCourseDetail({ courseId });
    }, [courseId]);

    const content = (
        <Row gutter={[16, 16]}>
            <Col span={18}>
                <AssignmentTabsContainer />
            </Col>
            <Col span={6}>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <CourseInfoContainer />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <MemberListContainer />
                    </Col>
                </Row>
            </Col>
        </Row>
    );

    if (courseDetailIsFetching) {
        return (
            <BaseLoadingPage />
        );
    }

    return (
        <BaseTemplate content={content} />
    );
};

export default withMemberInCourse(withAuth(CoursePage));

import { useState } from 'react';

import { Select } from 'antd';

import { ExecutionResultListContainer } from './ExecutionResultListContainer';
import { RecordListContainer } from './RecordListContainer';
import { SubmissionListContainer } from './SubmissionListContainer';

const RECORD_OPTIONS = {
    ALL_SUBMISSION: 'allSubmission',
    SUBMISSION: 'submission',
    TESTING_RESULT: 'testingResult',
};

const options = [
    {
        label: '全部紀錄',
        value: RECORD_OPTIONS.ALL_SUBMISSION,
    },
    {
        label: '提交紀錄',
        value: RECORD_OPTIONS.SUBMISSION,
    },
    {
        label: '測試紀錄',
        value: RECORD_OPTIONS.TESTING_RESULT,
    },
];

export const RecordContainer = ({ id }) => {
    const [option, setOption] = useState(RECORD_OPTIONS.ALL_SUBMISSION);

    const getList = () => {
        switch (option) {
            case RECORD_OPTIONS.TESTING_RESULT:
                return <ExecutionResultListContainer id={id} />;
            case RECORD_OPTIONS.SUBMISSION:
                return <SubmissionListContainer id={id} />;
            default:
                return <RecordListContainer id={id} />;
        }
    };

    return (
        <>
            <Select
                value={option}
                onChange={setOption}
                options={options}
            />
            {getList()}
        </>
    );
};

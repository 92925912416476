import Typography from '@mui/material/Typography';

import { Footer } from '../../components/layout';
import { basePalette } from '../../theme/baseTheme';

export const FooterContainer = () => (
    <Footer sx={{ background: basePalette.primary_dark }}>
        <Typography
            variant="body2"
        >
            Smart Coding Tutor © 2023
        </Typography>
    </Footer>
);

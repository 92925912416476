import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { TeacherAdminTemplate } from '../../../../template/pages/with-hocs';
import { TEACHER_SIDE_MENU_NAME } from '../../constants';
import {
    AssignmentContainer,
    CreateProblemContainer,
} from '../../containers/teacher';

export const AssignmentPage = () => {
    const { assignmentId } = useParams();

    const content = (
        <>
            <AssignmentContainer />
            <Row style={{ marginTop: 10 }}>
                <Col span={10} />
                <Col span={4}>
                    <CreateProblemContainer />
                </Col>
                <Col span={10} />

            </Row>
        </>
    );

    return (
        <TeacherAdminTemplate
            selectedKey={`assignment-${assignmentId}`}
            openKey={TEACHER_SIDE_MENU_NAME.ASSIGNMENTS_LIST}
            content={content}
        />
    );
};

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const OutputBox = styled(Box)(() => ({
    color: 'rgb(57, 58, 52)',
    fontFamily: 'Consolas, "Bitstream Vera Sans Mono", "Courier New", Courier, monospace',
    direction: 'ltr',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    fontSize: '0.9em',
    lineHeight: '1.2em',
    tabSize: '4',
    hyphens: 'none',
    padding: '0px 16px',
    paddingTop: '16px',
    margin: '0.5em 0px',
    overflow: 'auto',
    border: '1px solid rgb(221, 221, 221)',
    backgroundColor: 'white',

    '& pre': {
        margin: '0px',
    },
}));

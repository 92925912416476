import { Content } from '../../../components/layout';
import { HeaderContainer } from '../../containers';

export const NoFooterTemplate = ({ content }) => (
    <>
        <HeaderContainer />
        <Content
            sx={{
                minHeight: 'calc(100vh - 64px)',
                padding: '8px',
            }}
        >
            {content}
        </Content>
    </>
);

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { LoginTemplate } from '../../../template/pages/with-hocs';
import { LoginContainer } from '../containers';

const FormCard = styled(Card)(() => ({
    height: '100px',
    width: '400px',
}));

export const LoginPage = () => {
    const content = (
        <Grid container marginTop="60px">
            <Grid item xs={1} />
            <Grid item xs={5} marginTop="20px">
                <Box textAlign="center" typography="h1" padding={2}>
                    <Box>
                        Smart
                    </Box>
                    <Box marginLeft="1.5em">
                        Coding
                    </Box>
                    <Box>
                        Tutor
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={6} paddingLeft="80px" sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '400px', boxShadow: 5, borderRadius: '10px' }}>
                    <FormCard>
                        <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <LoginContainer />
                        </CardContent>
                    </FormCard>
                </Box>
            </Grid>
        </Grid>
    );

    return (
        <LoginTemplate content={content} />
    );
};

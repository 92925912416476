import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { REQUEST_STATUS } from '../../constants';
import ROUTES from '../../router/routes';
import {
    selectIsAuthenticated,
    selectVerifyTokenStatus,
    verify,
} from '../../store/slices/authSlice';
import { BaseLoadingPage } from '../../template/loading';
import { Cookie } from '../../utils/cookie';

// eslint-disable-next-line react/function-component-definition
export const withAuthRedirecting = (Component, shouldRedirect) => (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const verifyStatus = useSelector(selectVerifyTokenStatus);
    const token = Cookie.get('token');

    const location = useLocation();

    const redirect = () => {
        localStorage.setItem('redirectUrl', location.pathname);
        navigate(ROUTES.LOGIN.link, { replace: true });
    };

    useEffect(() => {
        if (verifyStatus === REQUEST_STATUS.NONE && !isAuthenticated) {
            dispatch(verify({ token }));
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (verifyStatus === REQUEST_STATUS.ERROR) {
            if (shouldRedirect) {
                redirect();
            }
        }
    }, [verifyStatus]);

    return (
        isAuthenticated || verifyStatus !== REQUEST_STATUS.NONE
            // eslint-disable-next-line react/jsx-props-no-spreading
            ? <Component {...props} />
            : <BaseLoadingPage />
    );
};

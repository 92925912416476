import { Button } from 'antd';

import Box from '@mui/material/Box';

export const LastFooter = ({
    isChecked = false,
    onBack = () => {},
    onSubmit = () => {},
    submitText = '新增',
}) => (
    <Box
        style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}
    >
        <Button onClick={onBack}>
            上一步
        </Button>
        <Button
            type="primary"
            onClick={onSubmit}
            disabled={!isChecked}
        >
            {submitText}
        </Button>
    </Box>
);

import { useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import { Menu } from 'antd';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

import ROUTES from '../../../../router/routes';
import {
    useGetAllAssignmentsByCourseQuery,
    useGetCourseDetailQuery,
} from '../../../../store/services/restApi';
import { basePalette } from '../../../../theme/baseTheme';
import { TEACHER_SIDE_MENU_NAME } from '../../constants';

const Title = styled(Typography)({
    color: basePalette.white,
    padding: '0 8% 0 8%',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 20,
    fontWeight: 'bold',
});

export const SiderContainer = ({ defaultSelectedKey, defaultOpenKey = '' }) => {
    const { courseId } = useParams();
    const {
        data: allAssignments = [],
        refetch: refetchAssignments,
    } = useGetAllAssignmentsByCourseQuery({ courseId });
    const {
        data: courseDetail = {},
    } = useGetCourseDetailQuery({ courseId });

    const items = [
        {
            key: TEACHER_SIDE_MENU_NAME.COURSE_INFO,
            label: (
                <Link
                    to={
                        ROUTES.TEACHER_COURSE.link
                            .replace(':courseId', courseId)
                    }
                >
                    課程資訊
                </Link>
            ),
        },
        {
            key: TEACHER_SIDE_MENU_NAME.CREATE_ASSIGNMENT,
            label: (
                <Link
                    to={
                        ROUTES.TEACHER_CREATE_ASSIGNMENT.link
                            .replace(':courseId', courseId)
                    }
                >
                    新增主題
                </Link>
            ),
        },
        {
            key: TEACHER_SIDE_MENU_NAME.ASSIGNMENTS_LIST,
            label: '所有主題',
            children: allAssignments.map((assignment) => ({
                key: `assignment-${assignment.id}`,
                label: (
                    <Link
                        to={
                            ROUTES.TEACHER_COURSE_ASSIGNMENT.link
                                .replace(':courseId', courseId)
                                .replace(':assignmentId', assignment.id)
                        }
                    >
                        {assignment.title}
                    </Link>
                ),
            })),
        },
    ];

    useEffect(() => {
        refetchAssignments({ courseId });
    }, [courseId]);

    return (
        <>
            <Title>{courseDetail.name}</Title>
            <Menu
                theme="dark"
                selectedKeys={[defaultSelectedKey]}
                mode="inline"
                items={items}
                defaultOpenKeys={defaultOpenKey === '' ? [] : [defaultOpenKey]}
            />
        </>
    );
};

import { useEffect } from 'react';

import {
    Form,
    Input,
    Radio,
    Typography,
} from 'antd';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { BaseWindow } from './BaseWindow';

const { Text } = Typography;

const BoldText = styled(Text)({
    fontWeight: 'bold',
    color: 'inherit',
    margin: '0 4px',
});

export const CreateCourseWindow = ({ reset = false, onSubmit = () => {}, visible = false }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (reset) {
            form.resetFields();
        }
    }, [reset]);

    const handleSubmit = (data) => {
        onSubmit(data);
    };

    return (
        <BaseWindow
            title="建立課程"
            windowBodyStyle={{
                padding: '24px',
            }}
            okButtonProps={{
                form: 'create-course-form',
                key: 'submit',
                htmlType: 'submit',
            }}
            visible={visible}
        >
            <Form
                id="create-course-form"
                name="create-course-form"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ isPrivate: false }}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Form.Item
                    label="課程名稱"
                    name="courseName"
                    rules={[{ required: true, message: '取一個超帥的課程名稱！' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={(
                        <Text>設定</Text>
                    )}
                    name="isPrivate"
                    rules={[{ required: true, message: '請選擇是否要公開！' }]}
                    tooltip={{
                        title: (
                            <>
                                <Box>公開：成員<BoldText>無須</BoldText>經過審核</Box>
                                <Box>私人：成員<BoldText>必須</BoldText>經過審核</Box>
                            </>
                        ),
                        placement: 'bottomLeft',
                    }}
                >
                    <Radio.Group>
                        <Radio value={false}>公開</Radio>
                        <Radio value={Boolean('true')}>私人</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </BaseWindow>
    );
};
